import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import Landing_Page from './landing_page/Landing_page'
import './scss/style.scss'
import Details from './views/widgets/Details'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {


  componentDidMount() {
    this.clearCacheData(); // Call function to clear cache on initial load
  }

  clearCacheData = () => {
    // Fetch your assets with a unique query parameter (cache busting)
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      scripts[i].src = scripts[i].src + '?v=' + Date.now();
      console.log(scripts[i].src,"checking")
    }

    // const links = document.getElementsByTagName('link');
    // for (let i = 0; i < links.length; i++) {
    //   links[i].href = links[i].href + '?v=' + Date.now();
    //   console.log( links[i].href,"checking1")
    // }

    // Inform user that cache has been cleared
    console.log("Complete Cache Cleared");
  };

  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/details" name="Login Page" element={<Details/>} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route path="/" name="Landing Page" element={<Landing_Page />} />  
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
