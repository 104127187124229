import React, { useEffect, useState } from 'react'
import './style.css'
import hero from './img/hero-img.png'
import hero1 from './img/hero1-img.png'
import logo from '../assets/images/logo-atzapp.png'
import google_play from '../assets/images/google.png'
import apple_store from '../assets/images/apple.png'
import { useNavigate } from 'react-router-dom'
// import {Button} from 'reactstrap'

const Landing_Page = () => {
  const [subscriptionData, setSubscriptionData] = useState()
  const navigate = useNavigate();
  const getSubscriptionData = () => {
    // fetch('https://api.catzapp.com/recruiter/mysubscription/')
    fetch(
      'https://api.catzapp.com/recruiter/mysubscription/',
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer  ${localStorage.getItem("token")}`,
        },
      }
    )
      .then(response => response.json())
      .then(data => {
        setSubscriptionData(data.data)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  console.log('SubscriptionData', subscriptionData)
  const handleLogin = () => {
    navigate('/login')
  }
  useEffect(() => {
    getSubscriptionData()
  }, [])
  return (
    <>
      {/* -------------------------Header-------------------------- */}
      <header id="header" className="fixed-top  header-transparent " >
        <div className="container d-flex align-items-center justify-content-between">

          <div className="logo" >
            {/* <h1><a href="index.html" style={{fontWeight:'bolder'}}>CatZapp</a></h1> */}
            <img src={logo} alt='' />
          </div>

          {/* <nav id="navbar" className="navbar"> */}
          <div>
            {/* <button className="btn btn-primary px-4 admin-btn btn mx-2" href="https://jobportalrecruiter.atwpl.com/" target="_blank" style={{ cursor: "pointer" }}>Go To Web</button> */}
            <button className="btn btn-primary px-4 admin-btn btn" onClick={handleLogin} style={{ cursor: "pointer" }}>Login</button>
          </div>
          <i className="bi bi-list mobile-nav-toggle"></i>
          {/* </nav> */}

        </div>
      </header>
      {/* --------------------------------Header end---------------------------- */}

      {/* --------------------------------------Hero Section----------------------------- */}
      <section id="hero" className="d-flex align-items-center">

        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1" data-aos="fade-up">
              {/* <div> */}
              <h1>CatZapp Admin Panel </h1> 
              {/* <h2>Lorem ipsum dolor sit amet, tota senserit percipitur ius ut, usu et fastidii forensibus voluptatibus. His ei nihil feugait</h2>

              <button href='https://atplg-my.sharepoint.com/personal/sonal_saurabh_ahom_tech/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fsonal%5Fsaurabh%5Fahom%5Ftech%2FDocuments%2FMicrosoft%20Teams%20Chat%20Files%2FJob%2DSeeker%2Dstaging%2Eapk&parent=%2Fpersonal%2Fsonal%5Fsaurabh%5Fahom%5Ftech%2FDocuments%2FMicrosoft%20Teams%20Chat%20Files&ga=1' target='_blank' style={{ border: 'none', background: 'none' }}><img src={google_play} alt='' /></button>
              <button href='#' target='_blank' style={{ border: 'none', background: 'none' }}><img src={apple_store} alt='' /></button> */}

              {/* </div> */}
            </div>
            <div className="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img" data-aos="fade-up">
              {/* <img src={hero} className="img-fluid" alt="" /> */}
              <img src={hero1} className="img-fluid" alt="" />
            </div>
          </div>
        </div>

      </section>
      {/* ------------------------------------Hero Section End----------------------------- */}

      {/* ------------------------------Pricing Section--------------------------------------- */}
      <section id="pricing" className="pricing">
        <div className="container">

          <div className="section-title">
            <h2>Pricing</h2>
            <p>Magnam dolores commodi suscipit.
               Necessitatibus eius consequatur ex aliquid fuga eum quidem.
                Sit sint consectetur velit. Quisquam quos quisquam cupiditate. 
                Et nemo qui impedit suscipit alias ea.
               Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
          </div>

          {/* <div className="row no-gutters">

          <div className="col-lg-4 box" data-aos="fade-right">
            <h3>Free</h3>
            <h4>$0<span>per month</span></h4>
            <ul>
              <li><i className="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i className="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i className="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li className="na"><i className="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
              <li className="na"><i className="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li>
            </ul>
            <a href="#" className="get-started-btn">Get Started</a>
          </div>

          <div className="col-lg-4 box featured" data-aos="fade-up">
            <h3>Business</h3>
            <h4>$29<span>per month</span></h4>
            <ul>
              <li><i className="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i className="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i className="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li><i className="bx bx-check"></i> Pharetra massa massa ultricies</li>
              <li><i className="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
            </ul>
            <a href="#" className="get-started-btn">Get Started</a>
          </div>

          <div className="col-lg-4 box" data-aos="fade-left">
            <h3>stagingeloper</h3>
            <h4>$49<span>per month</span></h4>
            <ul>
              <li><i className="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i className="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i className="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li><i className="bx bx-check"></i> Pharetra massa massa ultricies</li>
              <li><i className="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
            </ul>
            <a href="#" className="get-started-btn">Get Started</a>
          </div>

        </div> */}

          {subscriptionData?.plan_details &&
            <div className="d-flex mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="plan mr-3" style={{ marginRight: '20px' }}>
                <p className="text">Current Plan</p>
                <p className="vip mt-2">{subscriptionData?.plan_details[0]?.plan_type}</p>
                <p className="heading mt-3">€ {subscriptionData?.plan_details[0]?.amount}/m</p>
              </div>
              <div className="plan">
                <p className="text">Upgrade To</p>
                <p className="premium-plan mt-2">{subscriptionData?.plan_details[1]?.plan_type}</p>
                <p className="heading mt-3">€ {subscriptionData?.plan_details[1]?.amount}/m</p>
              </div>
            </div>
          }

        </div>


      </section>

      {/* -------------------------End Pricing Section-------------------------- */}

      {/* ---------------------------------Footer----------------------- */}

      <div id="footer">

        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h4>Join Our Newsletter</h4>
                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                <form action="" method="post">
                  <input type="email" name="email" /><input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-top" >
          <div className="container">
            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>

              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>CatZapp</h3>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Home</a></li> */}
                  <li><i className="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="#">Carrers</a></li>
                  {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li> */}
                  <li><i className="bx bx-chevron-right"></i> <a href="#">Contact us</a></li>
                </ul>
              </div>

              {/* <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Web stagingelopment</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
            </ul>
          </div> */}

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p>
                <div className="social-links mt-3">
                  <a href="https://www.twitter.com/" className="twitter"><i className="fab fa-twitter"></i></a>
                  <a href="https://www.facebook.com/" className="facebook"><i className="fab fa-facebook-f"></i></a>
                  <a href="https://www.instagram.com/" className="instagram"><i className="fab fa-instagram"></i></a>
                  <a href="https://www.youtube.com/" className="google-plus"><i className="fab fa-youtube"></i></a>
                  <a href="https://www.linkedin.com/" className="linkedin"><i className="fab fa-linkedin"></i></a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container py-4">
          <div className="copyright">
            &copy; Copyright <strong><span>CatZapp</span></strong>. All Rights Reserved
          </div>
          {/* <div className="credits"> */}
          {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/free-bootstrap-app-landing-page-template/ --> */}
          {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
          {/* </div> */}
        </div>
      </div>

    </>
  )
}

export default Landing_Page