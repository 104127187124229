export const lang =
{
  "en": {
    "language": "English",
    "tab": {
      "Home": "Home",
      "My Vacancies": "My Vacancies",
      "Post": "Post",
      "Unlocked Profiles": "Unlocked Profiles",
      "My Profile": "My Profile"
    },
    "Home": {
      "Hey, There": "Hello",
      "Hey,": "Hello,",
      "Credits": "Credits",
      "Search by Candidates/categories/Services": "Search by Candidates/Industries/Services",
      "Job Categories": "Professional Title",
      "Show More": "Show More",
      "Show Less": "Show Less",
      "Your Subscription Info": "Your Subscription Info",
      "Upgrade your subscription to fulfil your requirements with our best plans.": "Upgrade your subscription to meet your requirements with our premium plans.",
      "Upgrade your Subscription": "Upgrade your Subscription",
      "0 %": "0 %",
      "Complete Your Profile": "Complete Your Profile",
      "Your profile is incomplete! Please complete your profile to find the right candidate for your job": "Your profile is incomplete! Please complete your profile to find the right candidate for your job",
      "Login Now": "Login Now",
      "Complete Now": "Complete Now",
      "Unlocked Profiles": "Unlocked Profiles",
      "All Profiles": "All Profiles",
      "Candidates Near Me": "Candidates Near Me",
      "View All": "View All",
      "Recommended Candidates": "Recommended Candidates",
      "Add to a List": "Add to a List",
      "Create New List": "Create New List",
      "Add": "Add",
      "Select Round": "Select Round",
      "Use my Current Location": " Use my Current Location",
      "Recent Locations": "Recent Locations",
      "Within 5 KM Radius": "Within 5 km Radius",
      "Within 10 KM Radius": "Within 10 km Radius",
      "Within 50 KM radius": "Within 50 km Radius",
      "Within 100 KM Radius": "Within 100 km Radius",
      "Notifications": "Notifications",
      "No New Notification Found": "No New Notification Found",
      "Delete": "Delete",
      "Read": "Read"
    },
    "BlackCard": {
      "Premium": "Premium",
      "Verified": "Verified",
      "About Me": "About Me",
      "Experience": "Experience",
      "Experience Required": "Experience Required",
      "Employment Type": "Type of Employment Contract",
      "Available": "Available",
      "Expected Salary": "Expected Salary",
      "Skills": "Skills",
      "Education": "Education",
      "Passout Year": "Graduation Year",
      "Licenses": "Licenses",
      "Contact Details Locked": "Contact Details Locked",
      "See Full Details": "See Full Details",
      "Less Than 1 KM Away": "Less than 1 km away",
      "KM Away": "Km away",
      "Availability for Part Time ": "Availability for part-time ",
      "You": "You",
      "Match": "Match",
      "Add To A List": "Add To A List",
      "Create New List": "Create New List",
      "Select Vacancy": "Select Vacancy",
      "Add": "Add",
      "Select Round": "Select Round",
      "Hire": "Hire",
      "Please unlock the profile first": "Please unlock the profile first",
      "Please do Login/Sign up": "Please Log In/Sign Up",
      "Are you sure to unlock the profile": "Are you sure you want to unlock the profile?",
      "Write your lists": "Write your lists"
    },
    "JobCategoriesdata": {
      "Candidate": "Candidate",
      "Candidates": "Candidates"
    },
    "RecommendedComp": {
      "Verified": "Verified",
      "Available": "Available",
      "Total Experience": "Total Experience",
      "Year": "Year",
      "Years": "Years",
      "Current Location": "Current Location",
      "Miles Away": "Miles Away",
      "Package": "Salary",
      "Match": "Match",
      "% Match": "% Match",
      "Last seen": "Last seen",
      "ago": "ago",
      "KM Away": "Km away"
    },
    "UnlockedProfileComp": {
      "KM": "km",
      "Match": "Match",
      "Unlocked on": "Unlocked on",
      "Total Experience": "Total Experience",
      "Current Location": "Current Location"
    },
    "Message": {
      "Filters": "Filters",
      "Search by Candidates/Keyword": "Search by Candidates/Keyword",
      "Messages": "Messages",
      "No Messages Found": "No Messages Found",
      "You": "You",
      "jobseeker": "Jobseeker",
      "Today": "Today"
    },
    "SearchEvents": {
      "Subscription Details": "Subscription Details",
      "Looking for": "Looking for",
      "Select Required Skills": "Select Required Skills",
      "Employment Type": "Type of employment contract",
      "Part Time": "Part Time",
      "Full Time": "Full Time",
      "Contract": "Contract",
      "Gender": "Gender",
      "Male": "Male",
      "Female": "Female",
      "Experience": "Experience",
      "Years": "Years",
      "Maximum Distance": "Maximum Distance",
      "Km": "Km",
      "Age": "Age",
      "Salary": "Salary",
      "K": "K",
      "Candidate Type": "Candidate Type",
      "Frees": "Frees",
      "Verified": "Verified",
      "Premium": "Premium",
      "Search Now": "Search Now",
      "Add To A List": "Add To A List",
      "Create New List": "Create New List",
      "Select Vacancy": "Select Vacancy",
      "Free": "Free",
      "Current Plan": "Current Plan",
      "VIP": "VIP"
    },
    "UnlockedPro": {
      "Available": "Available",
      "Unlocked Profiles": "Unlocked Profiles",
      "Search by Candidate Name": "Search by Candidate Name",
      "Filters": "Filters",
      "Verified": "Verified",
      "Available 20 Hrs/week": "Available 20 hrs/week",
      "Total Experience": "Total Experience",
      "Years": "Years",
      "Current Location": "Current Location",
      "km": "km",
      "Package": "Salary",
      "Match": "Match",
      "Unlocked on": "Unlocked on",
      "Add to a List": "Add to a List",
      "Create New List": "Create New List",
      "No Data Found": "No Data Found"
    },
    "CreditHistory": {
      "Credit balance": "Credit balance",
      "Available Credits": "Available Credits",
      "Used Credits": "Used Credits",
      "Total Credits": "Total Credits",
      "Subscription Details": "Subscription Details",
      "Plan": "Plan",
      "Days Left": "Days Left",
      "days Validity": "days Validity",
      "Purchased Date": "Purchased Date",
      "Validity": "Validity",
      "days": "days",
      "Amount": "Amount",
      "Payment Mode": "Payment Mode",
      "Credit History": "Credit History",
      "Recharge Credit": "Recharge Credit",
      "Select no. of Credits": "Select no. of Credits",
      "Credits": "Credits",
      "Pay € 30": "Pay € 30",
      "Premium": "Premium",
      "Credit Balance & History": "Credit Balance & History",
      "Upgrade Plan": "Upgrade Plan",
      "Unlocked By": "Unlocked By",
      "Credit Top-up": "Credit Top-up",
      "End Date": "End Date"
    },
    "PaymentSuccesfull": {
      "Thank you !": "Thank you!",
      "Your Payment was Successfull": "Your payment has been successfully processed",
      "Your Transcation ID: 1236589742 & Your plan Renews on 15/04/2021": "Your Transcation ID: 1236589742 & Your plan Renews on 15/04/2021",
      "Post Vacancy": "Post Vacancy",
      "Explore Candidates": "Explore Candidates"
    },
    "Subscription": {
      "Free": "Free",
      "Vip": "Vip",
      "Premium": "Premium",
      "Current Plan": "Current Plan",
      "€ 300/pm": "300€/ month",
      "Upgrade to": "Upgrade to",
      "€ 499/pm": "499€/ month",
      "Upgrade Now": "Upgrade Now",
      "Credit": "Credit",
      "Payment successful": "Payment successful"
    },
    "RecommBlackCard": {
      "Select Vacancy": "Select Vacancy",
      "Add to a List": "Add to a List",
      "Create New List": "Create New List",
      "Add": "Add",
      "Select Round": "Select Round",
      "10 %": "10 %",
      "%": "%",
      "Match": "Match",
      "Hire": "Hire"
    },
    "Hiring": {
      "Select Vacancy": "Select Vacancy",
      "Hiring Details": "Hiring Details",
      "Vacancy Details": "Vacancy Details",
      "Job Duration": "Job Duration",
      "Submit": "Submit",
      "Add to a List": "Add to a List",
      "Create New List": "Create New List",
      "Add": "Add"
    },
    "UnlockedProfileBlackScreen": {
      "Add to a List": "Add to a List",
      "Create New List": "Create New List",
      "Add": "Add",
      "Done": "Done",
      "Select Round": "Select Round",
      "Match": "Match",
      "Hire": "Hire"
    },
    "CandidateNearMapScreen": {
      "Less than 1 Miles away": "Less than 1 Mile away",
      "Miles away": "Miles away",
      "10% Match": "10% Match",
      "% Match": "% Match",
      "View Profile": "View Profile",
      "10%": "10%",
      "%": "%"
    },
    "SearchScreen": {
      "Search by candidates title, industry Title": "Search by Candidates Title, Industry Title",
      "Filters": "Filters",
      "Experience Between": "Experience Between",
      "Year": "Year",
      "Within": "Within",
      " mi of Radius": " mi of Radius",
      "Salary Between": "Salary Between",
      "K": "K",
      "No Data Found": "No Data Found",
      "Select Vacancy ": "Select Vacancy ",
      "Select Round": "Select Round",
      "Add to a List": "Add to a List",
      "Create New List": "Create New List",
      "Add": "Add",
      "10 % Add": "10 % Add",
      "%": "%",
      "Match": "Match",
      "Hire": "Hire"
    },
    "EditProfile": {
      "Upload image": "Upload image",
      "Personal Information": "Personal Information",
      "Submit": "Submit"
    },
    "HireDetails": {
      "Hires": "Hires",
      "Time Sheet": "Time Sheet",
      "View Full Profile": "View Full Profile",
      "OnGoing Job": "OnGoing Job",
      "Hours Worked": "Hours Worked",
      "Hours": "Hours",
      "Job Duration": "Job Duration",
      "Hired Date": "Hired Date",
      "Verified": "Verified",
      "All times are in your local your local (PTS)": "All times are in your local time (PT)"
    },
    "SuccessfulHire": {
      "Your Hire Request to ramesh has been sent Successfully": "Your hire request to Ramesh has been sent successfully",
      "Resume Hiring": "Resume Hiring"
    },
    "ApplicantsDetails": {
      "Filters": "Filters :",
      "Availability for Part Time": "Availability for Part-Time",
      "99% ": "99% ",
      "match": "Match",
      "Hire": "Hire"
    },
    "NoDataFound": {
      "Opps ! ": "Oops! ",
      "No Data Found ": "No Data Found ",
      "Post New Vacancy": "Post New Vacancy"
    },
    "PostNewVacancy": {
      "Confirm to Reject": "Confirm to Reject",
      "Confirm to Accept": "Confirm to Accept",
      "Select Required Skills": "Select Required Skills",
      "Job Description": "Job Description",
      "Select Required Experience": "Select Required Experience",
      "No Of Vacancies": "No. of Vacancies",
      "Job Duration(In Days)": "Job Duration(in days)",
      "Enter Compensation Amount": "Enter Compensation Amount",
      "Post Vacancy": "Post Vacancy",
      "Enter Job Title": "Enter Job Title",
      "Company Name (Please Update Your Profile)": "Company Name (Please Update Your Profile)",
      "Select Job Category": "Select Profession type",
      "Job Details": "Job Details",
      "Is this vacancy Urgent ? ": "Is this vacancy Urgent ? ",
      "Is Liscense Requrired ?": "Is Liscense Required?",
      "Employment Details": "Type of Employment Contract",
      "Compensation Details": "Compensation Details",
      "Company Details": "Company Details",
      "Work Location": "Work Location",
      "Contact Person Name": "Contact Person Name",
      "Contact Number": "Contact Number",
      "Select Highest Degree": "Select Highest Degree",
      "Highest Degree Completion Year": "Highest Degree Completion Year",
      "Maximum Application Required": "Maximum Application Required",
      "Enter Search Radius (In Meters)": "Enter Search Radius (in meters)",
      "Enter Short Description": "Enter Short Description",
      "Qualification Required": "Qualification Required",
      "Post Setting": "Post Setting",
      "Review & Post Vacancy": "Review & Post Vacancy"
    },
    "PostSuccessFulMessage": {
      "Edit Vacancy": "Edit Vacancy",
      "Post SuccessFul": "Post Successful",
      "Vacancy Posted Successfully !": "Vacancy Posted Successfully!",
      "Post Another Vacancy": "Post Another Vacancy"
    },
    "MyProfile": {
      "Welcome to Catzapp": "Welcome to Catzapp",
      "Login": "Log In",
      "Log Out": "Log Out",
      "My Profile": "My Profile",
      "Create an Account": "Create an Account",
      "My Subscriptions": "My Subscriptions",
      "My Lists": "My Lists",
      "My Vacancies": "My Vacancies",
      "My Unlocked Candidates": "My Unlocked Candidates",
      "Sub Users": "Sub Users",
      "Settings": "Settings",
      "Language": "Language",
      "Alert/Notifications": "Alert/Notifications",
      "Privacy & Policies": "Privacy Policy",
      "Contact Support": "Contact Support",
      "Update Profile": "Update Profile",
      "Upload Image": "Upload Image",
      "Personal Information": "Personal Information",
      "Submit": "Submit",
      "Upolad Your Company Image": "Upload Your Company Image",
      "About Your Company": "About Your Company",
      "Company Location": "Company Location",
      "Company Website": "Company Website",
      "Company Name": "Company Name",
      "Add Your Company": "Add Your Company",
      "Select Company": "Select Company",
      "Email": "Email",
      "Last Name": "Last Name",
      "First Name": "First Name",
      "My List Screen": "My List Screen",
      "Remove": "Remove",
      "Select Language": "Select Language"
    },
    "NoUserFound": {
      "Opps !": "Oops",
      "No Sub User Found": "No Sub User Found",
      "Create Sub User": "Create Sub User"
    },
    "NewSubUser": {
      "User Details": "User Details",
      "Enter User Name": "Enter User Name",
      "Enter First Name": "Enter First Name",
      "Enter Last Name": "Enter Last Name",
      "Enter Email Address": "Enter Email Address",
      "Enter Mobile Number": "Enter Mobile Number",
      "Enter Password": "Enter Password",
      "Confirm Password": "Confirm Password",
      "Update Sub User ": "Update Sub User ",
      "Create Sub User ": "Create Sub User ",
      "Enter Full Name": "Enter Full Name"
    },
    "MyList": {
      "No Data Found": "No Data Found",
      "Remove": "Remove",
      "Available": "Available"
    },
    "Settings": {
      "Email Notification": "Email Notification",
      "Notification": "Notification",
      "Update Password": "Update Password"
    },
    "PrivacyPolicies": {
      "PRIVACY POLICY": "PRIVACY POLICY",
      "Welcome to Recruiter": "Welcome to Recruiter",
      "1. Introduction": "1. Introduction",
      "2. Definitions": "2. Definitions",
      "3. Information Collection and Use": "3. Information Collection and Use",
      "4. Types of Data Collected ": "4. Types of Data Collected ",
      "5. Types of Data Collected": "5. Types of Data Collected"
    },
    "ContactSupport": {
      "Submit": "Submit"
    },
    "MyListsScreen": {
      "Required": "Required",
      "Immediately": "Immediately",
      "Total Experience": "Total Experience",
      "Years": "Years",
      "Year": "Year",
      "Work Location": "Work Location",
      "Package": "Salary"
    },
    "PlayListBlackCard": {
      "Select Vacancy": "Select Vacancy",
      "Select Round": "Select Round",
      "Add to a List ": "Add to a List ",
      "Create New List ": "Create New List ",
      "Add": "Add ",
      "Done": "Done",
      "Match": "Match",
      "Add Your List": "Add Your List"
    },
    "SignUp": {
      "Enter Your Name": "Enter Your Name",
      "Enter Your Phone Number": "Enter Your Phone Number",
      "Enter Your Email": "Enter Your Email",
      "Enter Password": "Enter Password",
      "Set a password": "Set a Password",
      "Confirm password ": "Confirm password",
      "Confirm": "Confirm"
    },
    "LoginPassword": {
      "Login": "Login",
      "Forgot Password": "Forgot Password",
      "Don't have an account?": "Don't have an account?",
      "SignUp": "Sign Up",
      "SKIP": "SKIP",
      "Please Enter MobileNumber & Password": "Please Enter Mobile Number & Password",
      "Please Enter Your Mobile Number": "Please Enter Your Mobile Number",
      "Please Enter Your Password": "Please Enter Your Password",
      "Please Enter Valid Password": "Please Enter Valid Password",
      "Please enter your mobile number to complete the login process.": "Please enter your mobile number to complete the login process.",
      "Enter Your Mobile Number": "Enter Your Mobile Number",
      "Enter Your Password": "Enter Your Password",
      "OTP": "OTP",
      "Login to Catzapp Recruiter": "Login to Catzapp Recruiter"
    },
    "ChangePassword": {
      "Please Enter Your New Password & Confirm Password": "Please Enter Your New Password & Confirm Password",
      "Please Enter Your New Password": "Please Enter Your New Password",
      "Please Enter Valid password": "Please Enter Valid password",
      "Please Enter Confirm Password": "Please Enter Confirm Password",
      "Password dosen't Match": "Passwords does not match",
      "Reset Your Password": "Reset Your Password",
      "Set a password": "Set a password",
      "Confirm password": "Confirm password",
      "Enter Your New password": "Enter Your New password",
      "Confirm Your New password": "Confirm Your New password"
    },
    "ResetPassword": {
      "Please Enter Mobile Number": "Please Enter Mobile Number",
      "Submit OTP": "Submit OTP",
      "Please Enter Otp": "Please Enter OTP",
      "We Have sent One Time Password to": "One Time Password has been sent to"
    },
    "interOtp": {
      "we have sent an OTP to": "we have sent an OTP to",
      "Did Not Receive Code Yet?": "Did Not Receive Code Yet?",
      "Resend OTP": "Resend OTP",
      "Please Enter OTP": "Please Enter OTP",
      "Submit": "Submit"
    },
    "forgetPassword": {
      "Send OTP": "Send OTP",
      "Get Password": "Get Password",
      "Enter Mobile Number": "Enter Mobile Number",
      "Please enter your mobile number to generate OTP": "Please enter your mobile number to generate OTP",
      "Please Enter Your Mobile Number": "Please Enter Your Mobile Number"
    },
    "myVacancy": {
      "Search by Job Title": "Search by Job Title",
      "My Vacancies": "My Vacancies",
      "No Data Found": "No Data Found",
      "Applications": "Applications",
      "Application": "Application",
      "Edit": "Edit",
      "Delete": "Delete",
      "Required": "Required",
      "Immediately": "Immediately",
      "Posted": "Posted",
      "ago": "ago",
      "Applicants": "Applicants",
      "Hire": "Hire",
      "Apply Filters": "Apply Filters",
      "Clear All": "Clear All",
      "Make Admin": "Make Admin"
    },
    "new": {
      "About Company": "About Company",
      "Latter": "Latter",
      "All": "All",
      "Not Premium": "Not Premium",
      "Premium": "Premium",
      "Buy Credit to Unlock the Candidates and Proceed further with the process to send messages, chat or call.": "Buy Credit to Unlock the Candidates and Proceed further with the process to send messages, chat or call.",
      "Are you sure you want to delete this chat?": "Are you sure you want to delete this chat?",
      "Recommended Profiles": "Recommended Profiles",
      "Map View": "Map View",
      "List View": "List View",
      "Read": "Read",
      "2 Credits will get deducted. Are you sure to unlock the profile?": "2 Credits will get deducted. Are you sure you want to unlock the profile?",
      "Subscribe Now": "Subscribe Now",
      "Please unlock profile first": "Please unlock profile first",
      "You do not have a login yet, Please do login/Signup to continue.": "You do not have a login yet. Please Log In/Sign Up to continue.",
      "Signup/Login": "Signup/Login",
      "Welcome to Catzapp": "Welcome to Catzapp",
      "Create Account": "Create Account",
      "Or": "Or",
      "Sign In": "Sign In",
      "Skip": "Skip",
      "Yes": "Yes",
      "No": "No",
      "Part Time": "Part-Time",
      "Full Time": "Full Time",
      "Contract": "Contract",
      "Daily": "Daily",
      "Weekly": "Weekly",
      "Monthly": "Monthly",
      "Yearly": "Yearly",
      "Upload Category Image": "Upload Profession Image",
      "From Date": "From Date",
      "To Date": "To Date",
      "Selected": "Selected",
      "Rejected": "Rejected",
      "First Round": "First Round",
      "Second Round": "Second Round",
      "Type Message": "Type Message",
      "Subject": "Subject",
      "Enter your Description": "Enter your Description",
      "Please Unlock Profile First": "Please Unlock Profile First",
      "Please Select Vacancy": "Please Select Vacancy",
      "Please Select From Date": "Please Select From Date",
      "Please Select To Date": "Please Select To Date",
      "Please Enter Details": "Please Enter Details",
      "Please Enter valid Email": "Please Enter valid Email",
      "Passwords should be a minimum of eight characters in length": "Password should be a minimum of eight characters in length",
      "Please Confirm Your Password": "Please Confirm Your Password",
      "Password Does Not Match": "Password Does Not Match"
    },
    "FinalFinal": {
      "You have created a hire request": "You have created a hire request",
      "Hire request already exists": "Hire request already exists",
      "Please Upload Image to add new categories": "Please Upload Image to add new profession",
      "Want to post vacancy please upgrade subscrption": "Want to post vacancy, please upgrade subscription",
      "Please enter number of vacancy": "Please enter number of vacancy",
      "Please enter search radius": "Please enter search radius",
      "Enter Your Email Address": "Enter Your Email Address",
      "Enter Your Phone Number": "Enter Your Phone Number",
      "Please Enter Title": "Please Enter Title",
      "Please Enter Description": "Please Enter Description",
      "Enter Your First Name": "Enter Your First Name",
      "Enter Your Last Name": "Enter Your Last Name",
      "Password must be minimum of 8 character": "Password must be minimum of 8 character",
      "Enter Confirm Password": "Enter Confirm Password",
      "Enter Valid Password (password must be combination of letters, numbers and special character": "Enter Valid Password (password must be combination of letters, numbers and special character",
      "Please Enter First Name": "Please Enter First Name",
      "Please Enter Last Name": "Please Enter Last Name",
      "Please Enter Email": "Please Enter Email",
      "Please Select or Add a Company": "Please Select or Add a Company",
      "Please Select Profile Picture": "Please Select Profile Picture",
      "Please enter Job Title": "Please enter Job Title",
      "Please select Company": "Please select Company",
      "Please Select Company Image": "Please Select Company Image",
      "Please Enter Company Detail": "Please Enter Company Detail",
      "Please select Job Category": "Please select Profession type",
      "Please select required Experience": "Please select required Experience",
      "Please enter Job Description": "Please enter Job Description",
      "Please enter short description": "Please enter short description",
      "Please select Skills": "Please select Skills",
      "Please select urgent vacancy": "Please select if or not vacancy is urgent",
      "Please select liscense required": "Please select if or not liscense required",
      "Please select to date": "Please select to date",
      "Please select from date": "Please select from date",
      "Please enter maximum number of application": "Please enter maximum number of application",
      "Please select category image": "Please select Profession image",
      "Please select Highest Degree": "Please select Highest Degree",
      "Please enter contact person number": "Please enter contact person´s number",
      "Please enter contact person name": "Please enter contact person´s name",
      "Please enter Job Location": "Please enter Job Location",
      "Please enter Compensation Amount": "Please enter Compensation Amount",
      "Please enter Compensation Details": "Please enter Compensation Details",
      "Please enter Job Duration": "Please enter Job Duration",
      "Please select employment details": "Please select Type of employment contract",
      ".png Images are not supported": ".png Images are not supported",
      "Please Enter Company Name": "Please Enter Company Name",
      "Please Enter Company Website": "Please Enter Company Website",
      "OK": "OK"
    },
    "LastL": {
      "Stored Messages": "Stored Messages",
      "Unlocked By": "Unlocked By",
      "User Type": "User Type",
      "Search": "Search",
      "Filter By": "Filter By",
      "Sort By": "Sort By",
      "Experience": "Experience",
      "Location Radius": "Location Radius",
      "Matching Percentage": "Matching Percentage",
      "Package Range": "Salary Range",
      "Passout Year": "Graduation Year",
      "Distance": "Distance",
      "Search by Area/Street/City Name": "Search by Area/Street/City Name",
      "Please select Highest Degree completion year": "Please select Highest Degree completion year"
    },
    "Lastest": {
      "NoAccount": "Don't have an account?",
      "Login": "Login",
      "Sec": "Sec",
      "Please Wait": "Please Wait",
      "UpGradePlan": "If you want to see candidate profile, please upgrade your plan",
      "Monthly": "Monthly",
      "ProfileUpdate": "Profile Updated Successfully",
      "NewCategory": "Add New Profession",
      "Submit": "Submit",
      "Unlocked": "Unlocked",
      "First Round": "First Round",
      "Second Round": "Second Round",
      "Selected": "Selected",
      "Rejected": "Rejected",
      "CandidateAlreadyExist": "Candidate already exists in this list",
      "Monday": "Monday",
      "Tuesday": "Tuesday",
      "Wednesday": "Wednesday",
      "Thursday": "Thursday",
      "Friday": "Friday",
      "Saturday": "Saturday",
      "Sunday": "Sunday",
      "LanguageAlert": "Please close the application and restart it to change the language.",
      "Hire": "Hire",
      "Hirerequest": "You have created a request for hire",
      "DeleteJob": "Are you sure you want to delete this job?",
      "Address": "Address",
      "Jobs": "Job(s)",
      "Login Successfully": "Login Successfully",
      "Hours": "Hours",
      "Premium": "Premium",
      "Minutes": "Minutes",
      "Invalid credentials": "Invalid credentials",
      "Status : Accepted": "Status : Accepted",
      "Status : Rejected": "Status : Rejected",
      "Unread": "Unread"
    },
    "Newtranslation": {
      "job": "View Jobs",
      "HireRequest": "View Hire Request",
      "Timesheet": "View Timesheet",
      "Message": "View Message",
      "Unlocked by recruiter": "Unlocked by recruiter",
      "Monthly Plan": "Monthly Plan",
      "Half Yearly Plan": "Half-Year Plan",
      "Yearly Plan": "Yearly Plan",
      "valid for ": "valid for ",
      "days": "days",
      "Feature": "Feature",
      "Includescredits": "Includes 10 credits per month.",
      "Credits can be carried forward each month": "Credits can be carried forward each month",
      "Upgrade Subcription": "Upgrade Subscription",
      "Newcandidate": "A new candidate applied to your job driver. Check now",
      "No relevant candidate found": "No relevant candidate found",
      "Years of": "Years of",
      "Candidates": "Candidates",
      "The payment has been canceled": "The payment has been canceled",
      "Applicant already exist": "Applicant already exist",
      "The payment method was setup successfully": "The payment method was set up successfully",
      "Your Hire Request has been sent successfully": "Your Hire Request has been sent successfully",
      "Slot Available": "Slot Available",
      "Task Description": "Task Description",
      "Time Sheet Approved successfully": "Time Sheet Approved successfully",
      "Time Sheet disapproved": "Time Sheet disapproved.",
      "Search By Candidate title": "Search By Candidate title",
      "This phone number doesn't exist": "This phone number does not exist",
      "OTP send successfully": "OTP sent successfully",
      "Login Successfully": "Login Successfull",
      "Invalid Credentials": "Invalid Credentials",
      "Enter Current Password": "Enter Current Password",
      "Enter New Password": "Enter New Password",
      "Confirm New Password": "Confirm New Password",
      "Cancel": "Cancel"
    },
    "YearMonth": {
      "Enter Valid Username": "Enter Valid Username",
      "Enter Valid Mobile Number": "Enter Valid Mobile Number",
      "Do you really want to Enable?": "Are you sure you want to enable?",
      "Do you really want to Disable?": "Are you sure you want to disable?",
      "Do you really want to Deactivate?": "Are you sure you want to Deactivate?",
      "Are you sure you want to delete this sub user?": "Are you sure you want to delete this sub user?",
      "Are you sure you want to make admin for this user?": "Are you sure you want to make this user an admin?",
      "Do you really want to approve?": "Are you sure you want to approve?",
      "Do you really want to delete?": "Are you sure you want to delete?",
      "Email or mobile number already exists for another user Or Please enter the same email & mobile as the seeker.": "Email or mobile number already exists for another user. Please enter the same email and mobile number as the seeker.",
      "User already exists.": "Add new subuser",
      "Enter Valid Website Url": "Add company website",
      "Enter Company Location": "Add business address",
      "Data Updated Sucessfully": "Update company information",
      "Company created Sucessfully": "Create a new company",
      "Client Name (with subuser)": "Client Name (with subuser)",
      "Profile(%)": "Profile(%)",
      "Username": "Username",
      "Password": "Password",
      "Vacancies": "Vacancies",
      "Describe here": "Describe here",
      "Yes": "Yes",
      "No": "No",
      "Active": "Active",
      "Deactive": "Deactive",
      "Paid": "Paid",
      "Free": "Free",
      "FREE": "FREE",
      "ByPass Is_True": "ByPass Is_True",
      "True": "True",
      "False": "False",
      "Subscription": "Subscription",
      "Top-Up": "Top-Up",
      "User": "User",
      "created": "created",
      "succeeded": "succeeded",
      "payment_failed": "payment_failed",
      "Revenue By Subscription": "Revenue By Subscription",
      "Revenue By Topup": "Revenue By Topup",
      "OPEN": "OPEN",
      "sub_user": "Sub_user",
      "jobseeker": "Jobseeker",
      "CLOSED": "CLOSED",
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "November": "November",
      "December": "December",
      "Today": "Today",
      "Yesterday": "Yesterday",
      "a minute ago": "a minute ago",
      "minutes ago": "minutes ago",
      "hours ago": "hours ago",
      "a hour ago": "an hour ago",
      "a day ago": "a day ago",
      "days ago": "days ago",
      "Do You Really Want To Delete Your Account": "Are You Sure You To Delete Your Account?",
      "User does not exists": "User does not exist",
      "phone, username already exist": "phone, username already exist",
      "This phone number doesn't exist": "This phone number does not exist",
      "Login Successfully": "Login Successfull",
      "User is deactivated by admin": "User is deactivated by admin",
      "Time sheet downloaded successfully, Please check your download folder": "Time sheet downloaded successfully. Please check your download folder",
      "Space are not allowed": "Space are not allowed",
      "Are you sure you want to Transfer Admin Right?": "Are you sure you want to Transfer Admin Right?",
      "Close": "Close",
      "certificate": "certificate",
      "Job Seeker": "Job Seeker",
      "phone, email already exist": "phone, email already exist",
      "email already exist": "email already exist",
      "phone already exist": "phone already exist",
      "You have created a new sub user": "You have created a new sub user",
      "You have updated a sub user's detail": "You have updated sub users details",
      "Admin": "Admin",
      "Sub-User": "Sub-User",
      "Created": "Created",
      "Year": "Year",
      "OTP verified Successfully": "OTP verified Successfully",
      "Login": "Login",
      "Please Enter Current Password": "Please Enter Current Password",
      "Please Enter New Password": "Please Enter New Password",
      "Password Does not Match": "Password Does not Match",
      "You cannot set the old password again": "You cannot set the old password again",
      "Password Reset": "Password Reset",
      "Thank You for connecting our support team. we'll get back to you soon": "Thank you for contacting our support team. We will get back to you shortly.",
      "Please correct the following errors.": "Please correct following errors:",
      "All Companny": "Companies",
      "Dashboard": "Dashboard",
      "Categories": "Categories",
      "Jobseekers": "Jobseekers",
      "Job Post Management": "Job Post Management",
      "Client Management": "Client Management",
      "By-pass Payment": "By-pass Payment",
      "Order History": "Order History",
      "Tickets": "Tickets",
      "Package": "Subscription Plans",
      "Transaction History": "Transaction History",
      "Logout": "Logout",
      "Auto Approve": "Auto Approve",
      "Data Is Loading": "Data Is Loading",
      "Search By Category/Added By": "Search By Category/Added By",
      "Image": "Image",
      "Rows per page:": "Rows per page",
      "Search Name/Email/Profile": "Search Name/Email/Profile",
      "Search By Recruiter/Jobs": "Search By Recruiter/Jobs",
      "Search Client/Company Name": "Search Client/Company Name",
      "RECRUITER PLAN": "RECRUITER PLAN",
      "Monthly Plan": "Monthly Plan",
      "Credit": "Credit",
      "Feature": "Feature",
      "Includes 10 credits per month.": "Includes 10 credits per month.",
      "Half Yearly Plan": "Half-Year Plan",
      "Credits can be carried forward each month.": "Credits can be carried forward each month.",
      "Yearly Plan": "Yearly Plan",
      "VISIBILITY": "VISIBILITY",
      "JOBSEEKER PLAN": "JOBSEEKER PLAN",
      "Edit Package Detail": "Edit Subscription Details",
      "Package Amount": "Amount",
      "Enter Package Amount": "Enter Amount",
      "Plan validity": "Subscription valid (in days)",
      "Enter Plan validity": "Enter subscription validity",
      "Plan token_credits": "Subscription token credits",
      "Enter Plan token_credits": "Enter Subsriction token credits",
      "Plan token_credits is_carryforward": "transfer Credits to next month",
      "Plan Recharge Duration": "Subscription Recharge Duration",
      "cancel": "cancel",
      "Save": "Save",
      "Filter Transaction": "Filter Transaction",
      "Service": "Service",
      "Amount": "Amount",
      "Payment Status": "Payment Status",
      "Transaction Date": "Transaction Date",
      "previous": "previous",
      "Next": "Next",
      "Total Records:": "Total Records:",
      "AutoApproval updated successfully": "AutoApproval updated successfully",
      "Upload category Image": "Upload category Image",
      "Categories tittle": "Category title",
      "Added Successfully": "Added Successfully",
      "Category Approved Successfully": "Category Approved Successfully",
      "Category Deleted Successfully": "Category Deleted Successfully",
      "Updated Deleted Successfully": "Updates Deleted Successfully",
      "Vacancy Disapproved": "Vacancy Disapproved",
      "Vacancy Verified Successfully": "Vacancy Verified Successfully",
      "Top-Up Successfully": "Top-Up Successfully",
      "On-boarded Client activated": "On-boarded Client activated",
      "On-boarded Client deactivated": "On-boarded Client deactivated",
      "Activated Successfully": "Activated Successfully",
      "Deactivated Successfully": "Deactivated Successfully",
      "Response Send": "Response Sent",
      "Credit Top up": "Credit Top up",
      "On-boarded Clients Enable": "On-boarded Clients Enable",
      "On-boarded Clients Disable": "On-boarded Clients Disable",
      "Credit for Premium": "Credit for Premium",
      "Amount for Premium": "Amount for Premium",
      "Credit for Free": "Credit for Free",
      "Amount for Free": "Amount for Free",
      "Credit for vacancy": "Credit for vacancy",
      "Unlock duration(in Days)": "Unlock duration(in Days)",
      "Unlock duration": "Unlock duration",
      "Token Limits updated successfully": "Token Limits updated successfully",
      "recruiter": "Recruiter",
      "Recruiter": "Recruiter",
      "Search by Company name": "Search by Company name",
      "Add Company": "Add Company",
      "Company Image": "Company Image",
      "Please upload file having extensions .jpeg/.jpg/.png only.": "Please upload file having extensions .jpeg/.jpg/.png only."
    }
  },
  "fi": {
    "language": "Suomi",
    "tab": {
      "Home": "Etusivu",
      "My Vacancies": "Avoimet työpaikkani",
      "Post": "Julkaise",
      "Unlocked Profiles": "Avatut profiilit",
      "My Profile": "Profiilini"
    },
    "Home": {
      "Hey, There": "Hei",
      "Hey,": "Hei,",
      "Credits": "Krediitit",
      "Search by Candidates/categories/Services": "Hae ehdokkaiden / toimialojen / palvelujen mukaan",
      "Job Categories": "Toimiala",
      "Show More": "Näytä enemmän",
      "Show Less": "Näytä vähemmän",
      "Your Subscription Info": "Tilauksesi tiedot",
      "Upgrade your subscription to fulfil your requirements with our best plans.": "Päivitä tilauksesi vastaamaan paremmin tarpeitasi.",
      "Upgrade your Subscription": "Päivitä tilauksesi",
      "Your profile is incomplete! Please complete your profile to find the right candidate for your job": "Profiilisi on puutteellinen! Täydennä profiilisi löytääksesi sopivan hakijan tehtävääsi.",
      "Login Now": "Kirjaudu sisään",
      "Complete Now": "Täydennä nyt",
      "Complete Your Profile": "Täydennä profiilisi",
      "Unlocked Profiles": "Avatut profiilit",
      "All Profiles": "Kaikki profiilit",
      "Candidates Near Me": "Minun lähellä olevat ehdokkaat",
      "View All": "Näytä kartalla",
      "Recommended Candidates": "Suositellut ehdokkaat",
      "Add to a List": "Lisää listaan",
      "Create New List": "Luo uusi lista",
      "Add": "Lisää",
      "Select Round": "Valitse kierros",
      "Use my Current Location": "Käytä nykyistä sijaintiani",
      "Recent Locations": "Viimeaikaiset sijainnit",
      "Within 5 KM Radius": "5 km:n säteellä",
      "Within 10 KM Radius": "10 km:n säteellä",
      "Within 50 KM radius": "50 km:n säteellä",
      "Within 100 KM Radius": "100 km:n säteellä",
      "Notifications": "Ilmoitukset",
      "No New Notification Found": "Uusia ilmoituksia ei löytynyt."
    },
    "JobCategoriesdata": {
      "Candidate": "Ehdokas",
      "Candidates": "Ehdokkaat"
    },
    "BlackCard": {
      "Premium": "Premium",
      "Verified": "Vahvistettu",
      "About Me": "Tietoja minusta",
      "Experience": "Työkokemus",
      "Experience Required": "Vaadittu työkokemus",
      "Employment Type": "Työsopimuksen tyyppi",
      "Available": "Saatavilla",
      "Expected Salary": "Palkkatoive",
      "Skills": "Taidot ja osaaminen",
      "Education": "Koulutus",
      "Passout Year": "Valmistumisvuosi",
      "Licenses": "Lisenssit, luvat ja todistukset",
      "Contact Details Locked": "Yhteystiedot lukittu",
      "See Full Details": "Näytä koko profiili",
      "Less Than 1 KM Away": "Alle 1 km päässä",
      "KM Away": "km päässä",
      "Availability for Part Time ": "Mahdollisuus osa-aikatyöhön ",
      "You": "Sinä",
      "Match": "Osuma",
      "Add To A List": "Lisää listalle",
      "Create New List": "Luo uusi lista",
      "Select Vacancy": "Valitse avoin työpaikka",
      "Add": "Lisää",
      "Select Round": "Valitse kierros",
      "Hire": "Palkkaa avoinna olevaan työhön",
      "Please unlock the profile first": "Avaa profiili ensin",
      "Please do Login/Sign up": "Kirjaudu sisään tai rekisteröidy",
      "Are you sure to unlock the profile": "Haluatko varmasti avata profiilin?",
      "Write your lists": "Tee omat listasi"
    },
    "RecommendedComp": {
      "Verified": "Vahvistettu",
      "Available": "Saatavilla",
      "Total Experience": "Työkokemus yhteensä ",
      "Year": "Vuosi",
      "Years": "Vuotta",
      "Current Location": "Nykyinen sijainti",
      "Miles Away": "Mailin päässä",
      "Package": "Palkka",
      "Buy Credit to Unlock the Candidates and Proceed further with the process to send messages, chat or call.": "Osta lisää krediittejä avataksesi hakijan tiedot ja lähetä viesti, chattäile tai soita puhelu.",
      "% Match": "% osuma",
      "Last seen": "Viimeksi nähdyt",
      "ago": "sitten",
      "KM Away": "km päässä"
    },
    "UnlockedProfileComp": {
      "KM": "km",
      "Match": "Osuma",
      "Unlocked on": "Avattu",
      "Total Experience": "Työkokemus yhteensä",
      "Current Location": "Nykyinen sijainti"
    },
    "Message": {
      "Filters": "Suodattimet",
      "Search by Candidates/Keyword": "Etsi ehdokkaiden / avainsanojen perusteella",
      "Messages": "Viestit",
      "No Messages Found": "Viestejä ei löytynyt",
      "You": "Sinä",
      "jobseeker": "Työnhakija",
      "Today": "Tänään"
    },
    "SearchEvents": {
      "Subscription Details": "Tilauksen tiedot",
      "Looking for": "Hae",
      "Select Required Skills": "Valitse vaaditut taidot ja osaaminen",
      "Employment Type": "Työsopimuksen tyyppi",
      "Part Time": "Osa-aikatyö",
      "Full Time": "Kokoaikainen työ",
      "Contract": "Sopimus",
      "Gender": "Sukupuoli",
      "Male": "Mies",
      "Female": "Nainen",
      "Experience": "Työkokemus",
      "Years": "Vuotta",
      "Maximum Distance": "Työpaikan maksimi etäisyys",
      "Km": "Km",
      "Age": "Ikä",
      "Salary": "Palkka",
      "K": "K",
      "Candidate Type": "Ehdokastyyppi",
      "Frees": "Vapaa",
      "Verified": "Vahvistettu",
      "Premium": "Premium",
      "Search Now": "Etsi nyt",
      "Free": "Ilmainen",
      "VIP": "VIP",
      "Current Plan": "Nykyinen tilaus"
    },
    "UnlockedPro": {
      "Unlocked Profiles": "Avatut profiilit",
      "Search by Candidate Name": "Hae ehdokkaan nimellä",
      "Filters": "Suodattimet",
      "Verified": "Vahvistettu",
      "Available": "Saatavilla",
      "Total Experience": "Työkokemus yhteensä",
      "Years": "Vuodet",
      "Current Location": "Nykyinen sijainti",
      "KM Away": "km päässä",
      "Package": "Palkka",
      "Match": "Osuma",
      "Unlocked on": "Avattu",
      "Add to a List": "Lisää listalle",
      "Create New List": "Luo uusi lista",
      "No Data Found": "Tuloksia ei löytynyt"
    },
    "CreditHistory": {
      "Credit balance": "Krediittien saldo",
      "Available Credits": "Käytettävissä olevat kreditit",
      "Used Credits": "Käytetyt krediitit",
      "Total Credits": "Krediittien kokonaismäärä",
      "Subscription Details": "Tilauksen tiedot",
      "Plan": "Tilaus",
      "Days Left": "Päiviä jäljellä",
      "days Validity": "päivää voimassaoloaikaa",
      "Purchased Date": "Ostopäivämäärä",
      "Validity": "Voimassaoloaika",
      "days": "päivää",
      "Amount": "Summa",
      "Credit Balance & History": "Krediittisaldo & historia",
      "Payment Mode": "Maksutapa",
      "Credit History": "Krediitti historia",
      "Recharge Credit": "Lisää krediittejä",
      "Select no. of Credits": "Valitse krediittien määrä",
      "Credits": "Krediitit",
      "Pay": "Maksa",
      "Premium": "Premium",
      "Unlocked By": "Avattu",
      "Upgrade Plan": "Päivitä tilaus",
      "Credit Top-up": "Krediittien lisäys",
      "End Date": "Päättymispäivämäärä"
    },
    "PaymentSuccesfull": {
      "Thank you !": "Kiitos!",
      "Your Payment was Successfull": "Maksusi onnistui",
      "Your Transcation ID: 1236589742 & Your plan Renews on": "Tapahtumatunnus: 1236589742 & tilauksesi uusitaan:",
      "Post Vacancy": "Julkaise avoin työpaikka",
      "Explore Candidates": "Tutustu ehdokkaisiin"
    },
    "Subscription": {
      "Free": "Ilmainen",
      "Vip": "VIP",
      "Premium": "Premium",
      "Current Plan": "Nykyinen tilaussuunnitelma",
      "€ 300/pm": "300 €/kk",
      "Upgrade to": "Päivitä",
      "€ 499/pm": "499 €/kk",
      "Upgrade Now": "Päivitä nyt",
      "Credit": "Krediitit",
      "Payment successful": "Maksu onnistui"
    },
    "RecommBlackCard": {
      "Select Vacancy": "Valitse avoin työpaikka",
      "Add to a List": "Lisää listalle",
      "Create New List": "Luo uusi lista",
      "Add": "Lisää",
      "Select Round": "Valitse kierros",
      "10 %": "10 %",
      "%": "%",
      "Match": "Osuma",
      "Hire": "Valitse työhön"
    },
    "Hiring": {
      "Select Vacancy": "Valitse avoin työpaikka",
      "Hiring Details": "Työhön ottamisen tiedot",
      "Vacancy Details": "Avoimen työpaikan yksityiskohtaiset tiedot",
      "Job Duration": "Työn kesto",
      "Submit": "Lähetä",
      "Add to a List": "Lisää listalle",
      "Create New List": "Luo uusi lista",
      "Add": "Lisää"
    },
    "UnlockedProfileBlackScreen": {
      "Add to a List": "Lisää listalle",
      "Create New List": "Luo uusi lista",
      "Add": "Lisää",
      "Done": "Valmis",
      "Select Round": "Valitse kierros",
      "Match": "Osuma",
      "Hire": "Palkkaa - valitse työhön"
    },
    "CandidateNearMapScreen": {
      "Less than 1 Miles away": "Alle 1 mailin päässä",
      "Miles away": "Mailin päässä",
      "10% Match": "10% osuma",
      "% Match": "% osuma",
      "View Profile": "Katso profiili",
      "10%": "10%",
      "%": "%"
    },
    "SearchScreen": {
      "Search by candidates title, industry Title": "Hae ehdokkaiden tittelin / toimialan mukaan",
      "Filters": "Suodattimet",
      "Experience Between": "Työkokemus välillä",
      "Year": "Vuosi",
      "Within": "Sisällä",
      "mi of Radius": " mailin säteellä",
      "Salary Between": "Palkka välillä",
      "K": "K",
      "No Data Found": "Tietoja ei löytynyt",
      "Select Vacancy": "Valitse avoin työpaikka",
      "Select Round": "Valitse kierros",
      "Add to a List": "Lisää listalle",
      "Create New List": "Luo uusi lista",
      "Add": "Lisää",
      "10 % Add": "10 % lisäys",
      "%": "%",
      "Match": "Osuma",
      "Hire": "Valitse työhön"
    },
    "EditProfile": {
      "Upload image": "Lataa kuva",
      "Personal Information": "Henkilökohtaiset tiedot",
      "Submit": "Lähetä"
    },
    "HireDetails": {
      "Hires": "Töihin valitut henkilöt",
      "View Full Profile": "Näytä koko profiili",
      "Time Sheet": "Työaikalenteri",
      "OnGoing Job": "Meneillään oleva työ",
      "Hours Worked": "Tuntia työskennelty",
      "Hours": "Tuntia",
      "Job Duration": "Työn kesto",
      "Hired Date": "Työn aloituspäivä",
      "Verified": "Vahvistettu"
    },
    "SuccessfulHire": {
      "Your Hire Request to ramesh has been sent Successfully": "Työntekijän työhönottamispyyntösi käyttäjälle Ramesh on lähetetty onnistuneesti.",
      "Resume Hiring": "Jatka palkkaamista "
    },
    "ApplicantsDetails": {
      "Filters": "Suodattimet:",
      "Availability for Part Time ": "Mahdollisuus osa-aikatyöhön",
      "99% ": "99% ",
      "match": "osuma",
      "Hire": "Palkkaa - valitse työhön"
    },
    "NoDataFound": {
      "Opps ! ": "Hups! ",
      "No Data Found ": "Tietoja ei löytynyt",
      "Post New Vacancy": "Julkaise uusi avoin työpaikka"
    },
    "PostNewVacancy": {
      "All times are in your local your local (PTS)": "Kaikki ajat ovat paikallista aikaa (PT)",
      "Confirm to Reject": "Vahvista hylkääminen",
      "Confirm to Accept": "Vahvista hyväksyminen",
      "Select Required Skills": "Valitse vaaditut taidot ja osaaminen",
      "Job Description": "Tehtävän kuvaus",
      "Select Required Experience": "Valitse vaadittu työkokemus",
      "No Of Vacancies": "Avoimien työpaikkojen lukumäärä",
      "Job Duration(In Days)": "Työn kesto (päivinä)",
      "Enter Compensation Amount": "Syötä palkka",
      "Post Vacancy": "Lähetä avoin työpaikka",
      "Enter Job Title": "Syötä työnimike",
      "Company Name (Please Update Your Profile)": "Yrityksen nimi (päivitä profiilisi)",
      "Select Job Category": "Valitse ammattinimike",
      "Job Details": "Työn tiedot",
      "Is this vacancy Urgent ? ": "Onko tämä avoin työpaikka kiireellinen?",
      "Is Liscense Requrired ?": "Vaaditaanko lupia?",
      "Employment Details": "Työsopimuksen tiedot",
      "Compensation Details": "Palkka tiedot",
      "Company Details": "Yrityksen tiedot",
      "Work Location": "Työpaikan sijainti",
      "Contact Person Name": "Yhteyshenkilön nimi",
      "Contact Number": "Yhteyshenkilön puhelinnumero",
      "Select Highest Degree": "Valitse korkein tutkinto",
      "Qualification Required": "Vaadittu pätevyys",
      "Highest Degree Completion Year": "Korkeimman tutkinnon valmistumisvuosi",
      "Maximum Application Required": "Vastaanotettavien työhakemuksien enimmäismäärä",
      "Enter Search Radius (In Meters)": "Syötä työpaikkailmoituksen näkyvyysalue (metreinä)",
      "Enter Short Description": "Syötä lyhyt kuvaus työpaikasta ja työtehtävistä",
      "Post Setting": "Työpaikkailmoituksen asetukset",
      "Review & Post Vacancy": "Tarkista ja julkaise avoin työpaikka"
    },
    "PostSuccessFulMessage": {
      "Edit Vacancy": "Muokkaa työpaikkailmoitusta",
      "Post SuccessFul": "Ilmoitus julkaistu",
      "Vacancy Posted Successfully !": "Avoin työpaikkailmoitus julkaistu onnistuneesti!",
      "Post Another Vacancy": "Lisää uusi työpaikkailmoitus"
    },
    "MyProfile": {
      "Welcome to Catzapp": "Tervetuloa Catzapp sovellukseen",
      "Login": "Kirjaudu",
      "Log Out": "Kirjaudu ulos",
      "My Profile": "Profiilini",
      "Create an Account": "Luo tili",
      "My Subscriptions": "Tilaukseni",
      "My Lists": "Listani",
      "My Vacancies": "Avoimet työpaikkani",
      "My Unlocked Candidates": "Avatut ehdokkaani",
      "Sub Users": "Alakäyttäjät",
      "Settings": "Asetukset",
      "Language": "Kieli",
      "Alert/Notifications": "Hälytykset / ilmoitukset",
      "Privacy & Policies": "Tietosuoja ja käytännöt",
      "Contact Support": "Ota yhteyttä tukeen",
      "Update Profile": "Päivitä profiili",
      "Upload Image": "Lataa kuva",
      "Personal Information": "Henkilökohtaiset tiedot",
      "Submit": "Lähetä",
      "Upolad Your Company Image": "Lataa yrityksen kuva / logo",
      "About Your Company": "Tietoa yrityksestäsi",
      "Company Location": "Yrityksen sijainti",
      "Company Website": "Yrityksen verkkosivut",
      "Company Name": "Yrityksen nimi",
      "Add Your Company": "Lisää yrityksesi",
      "Select Company": "Valitse yritys",
      "Email": "Sähköposti",
      "Last Name": "Sukunimi",
      "First Name": "Etunimi",
      "My List Screen": "Listanäkymä",
      "Select Language": "Valitse kieli"
    },
    "NoUserFound": {
      "Opps !": "Hups!",
      "No Sub User Found": "Alakäyttäjiä ei löytynyt",
      "Create Sub User": "Luo alakäyttäjä"
    },
    "NewSubUser": {
      "User Details": "Alakäyttäjän tiedot",
      "Enter User Name": "Syötä käyttäjänimi",
      "Enter First Name": "Syötä etunimi",
      "Enter Last Name": "Syötä sukunimi",
      "Enter Email Address": "Syötä sähköpostiosoite",
      "Enter Mobile Number": "Syötä puhelinnumero",
      "Enter Password": "Syötä salasana",
      "Confirm Password": "Vahvista salasana",
      "Update Sub User ": "Päivitä alakäyttäjä",
      "Create Sub User ": "Luo alakäyttäjä",
      "Enter Full Name": "Syötä koko nimi"
    },
    "MyList": {
      "No Data Found": "Tietoja ei löytynyt",
      "Remove": "Poista",
      "Available": "Saatavilla"
    },
    "Settings": {
      "Email Notification": "Sähköposti-ilmoitukset",
      "Notification": "Ilmoitukset",
      "Update Password": "Päivitä salasana"
    },
    "PrivacyPolicies": {
      "PRIVACY POLICY": "TIETOSUOJASELOSTE",
      "Welcome to Recruiter": "Tervetuloa Catzapp Recruiteriin, rekrytoijan sovellukseen",
      "1. Introduction": "1. Johdanto",
      "2. Definitions": "2. Määritelmät",
      "3. Information Collection and Use": "3. Tietojen kerääminen ja käyttö",
      "4. Types of Data Collected ": "4. Kerätyt tietotyypit ",
      "5. Types of Data Collected": "5. Kerätyt tietotyypit"
    },
    "ContactSupport": {
      "Submit": "Lähetä"
    },
    "MyListsScreen": {
      "Required": "Vaatimukset",
      "Immediately": "Välittömästi",
      "Total Experience": "Työkokemus yhteensä",
      "Years": "Vuotta",
      "Year": "Vuosi",
      "Work Location": "Työpaikan sijainti",
      "Package": "Palkka"
    },
    "PlayListBlackCard": {
      "Select Vacancy": "Valitse avoin työpaikka",
      "Select Round": "Valitse kierros",
      "Add to a List ": "Lisää listalle ",
      "Create New List ": "Luo uusi lista ",
      "Add": "Lisää ",
      "Done": "Tehty",
      "Match": "Osuma",
      "Add Your List": "Lisää oma lista"
    },
    "SignUp": {
      "Enter Your Name": "Syötä nimesi",
      "Enter Your Phone Number": "Syötä puhelinnumerosi",
      "Enter Your Email": "Syötä sähköpostiosoitteesi",
      "Enter Password": "Syötä salasana",
      "Enter Valid Password": "Syötä kelvollinen salasana",
      "Password Does Not Match": "Salasanat eivät täsmää",
      "Set a password": "Aseta salasana",
      "Confirm password ": "Vahvista salasana ",
      "Confirm": "Vahvista"
    },
    "LoginPassword": {
      "Login": "Kirjaudu sisään",
      "Forgot Password": "Unohtuiko salasana?",
      "Do not have an account?": "Ei käyttäjätiliä?",
      "SignUp": "Rekisteröidy",
      "SKIP": "OHITA",
      "Please Enter MobileNumber & Password": "Syötä puhelinnumero ja salasana",
      "Please Enter Your Mobile Number": "Syötä puhelinnumerosi",
      "Please Enter Your Password": "Syötä salasanasi",
      "Please Enter Valid Password": "Syötä kelvollinen salasana",
      "Please enter your mobile number to complete the login process.": "Syötä puhelinnumerosi kirjautumisen viimeistelyä varten.",
      "Enter Your Mobile Number": "Syötä puhelinnumerosi",
      "Enter Your Password": "Syötä salasanasi",
      "OTP": "OTP",
      "Login to Catzapp Recruiter": "Kirjaudu Catzapp Recruiter rekrytoijan sovellukseen"
    },
    "ChangePassword": {
      "Please Enter Your New Password & Confirm Password": "Syötä uusi salasana ja vahvista se",
      "Please Enter Your New Password": "Syötä uusi salasana",
      "Please Enter Valid password": "Syötä kelvollinen salasana",
      "Please Enter Confirm Password": "Vahvista salasana",
      "Password dosen't Match": "Salasanat eivät täsmää",
      "Reset Your Password": "Nollaa salasanasi",
      "Set a password": "Syötä salasana",
      "Confirm password": "Vahvista salasana",
      "Enter Your New password": "Syötä uusi salasana",
      "Confirm Your New password": "Vahvista uusi salasana"
    },
    "ResetPassword": {
      "Please Enter Mobile Number": "Syötä puhelinnumero",
      "Please Enter Otp": "Syötä OTP",
      "Submit OTP": "Lähetä OTP",
      "We Have sent One Time Password to": "OTP kertakäyttösalasana on lähetetty "
    },
    "interOtp": {
      "we have sent an OTP to": "Olemme lähettäneet OTP kertakäyttöisen salasanan osoitteeseen",
      "Did Not Receive Code Yet?": "Etkö vielä vastaanottanut koodia?",
      "Resend OTP": "Lähetä OTP uudelleen",
      "Please Enter OTP": "Syötä OTP",
      "Submit": "Vahvista"
    },
    "forgetPassword": {
      "Send OTP": "Lähetä OTP",
      "Get Password": "Hanki salasana",
      "Enter Mobile Number": "Syötä puhelinnumero",
      "Please enter your mobile number to generate OTP": "Kirjoita matkapuhelinnumerosi OTP:n luomiseksi",
      "Please Enter Your Mobile Number": "Syötä puhelinnumerosi"
    },
    "myVacancy": {
      "Search by Job Title": "Etsi työnimikkeen perusteella",
      "My Vacancies": "Minun avoimet työpaikat",
      "No Data Found": "Tietoja ei löytynyt",
      "Applications": "Hakemukset",
      "Edit": "Muokkaa",
      "Delete": "Poista",
      "Required": "Vaadittu",
      "Immediately": "Välittömästi",
      "Posted": "Lähetetty",
      "ago": "sitten",
      "Applicants": "Hakijat",
      "Hire": "Ota työhön",
      "Apply Filters": "Käytä suodattimia",
      "Clear All": "Tyhjennä kaikki",
      "Application": "Hakemus",
      "Make Admin": "Nimeä ylläpitäjäksi"
    },
    "new": {
      "About Company": "Tietoa yrityksestä",
      "Latter": "Jälkimmäinen",
      "All": "Kaikki",
      "Not Premium": "Ei Premium",
      "Premium": "Premium",
      "Buy Credit to Unlock the Candidates and Proceed further with the process to send messages, chat or call.": "Osta  krediittejä avataksesi hakijan tiedot ja jatkaaksesi viestintää, chatin tai puhelun lähettämistä.",
      "Are you sure you want to delete this chat?": "Haluatko varmasti poistaa tämän keskustelun?",
      "Recommended Profiles": "Suositellut profiilit",
      "Map View": "Karttanäkymä",
      "List View": "Listanäkymä",
      "Read": "Lue",
      "2 Credits will get deducted. Are you sure to unlock the profile?": "2 krediittiä vähennetään. Haluatko varmasti avata tiedot?",
      "Subscribe Now": "Tilaa nyt",
      "Please unlock profile first": "Avaa profiili ensin",
      "You do not have a login yet, Please do login/Signup to continue.": "Et ole vielä sisäänkirjautunut. Kirjaudu sisään tai rekisteröidy jatkaaksesi.",
      "Signup/Login": "Rekisteröidy / Kirjaudu sisään",
      "Welcome to Catzapp": "Tervetuloa Catzappiin",
      "Create Account": "Luo tili",
      "Or": "Tai",
      "Sign In": "Kirjaudu sisään",
      "Skip": "Ohita",
      "Yes": "Kyllä",
      "No": "Ei",
      "Part Time": "Osa-aikainen",
      "Full Time": "Kokoaikainen",
      "Contract": "Sopimus",
      "Daily": "Päivittäin",
      "Weekly": "Viikoittain",
      "Monthly": "Kuukausittain",
      "Yearly": "Vuosittain",
      "Upload Category Image": "Valitse työtä esittävä kuvake kartalle",
      "From Date": "Aloituspäivä",
      "To Date": "Lopetuspäivä",
      "Selected": "Valittu",
      "Rejected": "Hylätty",
      "First Round": "Ensimmäinen kierros",
      "Second Round": "Toinen kierros",
      "Type Message": "Kirjoita viesti",
      "Subject": "Aihe",
      "Enter your Description": "Työpaikan kuvaus ja työtehtävät",
      "Please Unlock Profile First": "Avaa profiili ensin",
      "Please Select Vacancy": "Valitse avoin työpaikka",
      "Please Select From Date": "Valitse työn aloituspäivä",
      "Please Select To Date": "Valitse työn lopetuspäivä",
      "Please Enter Details": "Syötä tiedot",
      "Please Enter valid Email": "Syötä sähköpostiosoitteesi",
      "Passwords should be a minimum of eight characters in length": "Salasanan tulee olla vähintään 8 merkkiä pitkä",
      "Please Confirm Your Password": "Vahvista salasanasi",
      "Password Does Not Match": "Salasanat eivät täsmää"
    },
    "FinalFinal": {
      "You have created a hire request": "Olet luonut työhönottopyynnön",
      "Hire request already exists": "Työhönottopyyntö on jo olemassa",
      "Please Upload Image to add new categories": "Lataa toimialaa esittävä kuvake (.jpg)",
      "Please select liscense required": "Valitse, tarvitseeko avoimeen paikkaan lupia (esim. hygianiapassi)",
      "Want to post vacancy please upgrade subscrption": "Jos haluat julkaista avoimen työpaikan, päivitä tilauksesi",
      "Please enter number of vacancy": "Anna avoimien työpaikkojen määrä",
      "Please enter search radius": "Anna hakusäde",
      "Enter Your Email Address": "Syötä sähköpostiosoitteesi",
      "Enter Your Phone Number": "Syötä puhelinnumerosi",
      "Please Enter Title": "Syötä otsikko",
      "Please Enter Description": "Syötä työtehtävän kuvaus",
      "Enter Your First Name": "Syötä etunimesi",
      "Enter Your Last Name": "Syötä sukunimesi",
      "Password must be minimum of 8 character": "Salasanan on oltava vähintään 8 merkkiä pitkä",
      "Enter Confirm Password": "Syötä salasana uudelleen",
      "Enter Valid Password (password must be combination of letters, numbers and special character": "Salasanan oltava vähintään 8 merkkiä pitkä, mukaan lukien isoja ja pieniä kirjaimia, numeroita sekä vähintään yksi erikoismerkki (@$!%*#?&)",
      "Please Enter First Name": "Syötä etunimi",
      "Please Enter Last Name": "Syötä sukunimi",
      "Please Enter Email": "Syötä sähköpostiosoite",
      "Please Select or Add a Company": "Valitse tai lisää yritys",
      "Please Select Profile Picture": "Valitse profiilikuva",
      "Please enter Job Title": "Syötä työnimike",
      "Please select Company": "Valitse yritys",
      "Please Select Company Image": "Valitse yrityksen logo",
      "Please Enter Company Detail": "Syötä yrityksen tiedot",
      "Please select Job Category": "Valitse ammattinimike",
      "Please select required Experience": "Valitse vaadittu työkokemus",
      "Please enter Job Description": "Syötä työntehtävän kuvaus",
      "Please enter short description": "Syötä työtehtävän lyhyt kuvaus",
      "Please select Skills": "Valitse taidot ja osaaminen",
      "Please select urgent vacancy": "Valitse onko avoin työpaikka kiireellinen",
      "Please select license required": "Valitse vaaditaanko työhön lisenssi",
      "Please select to date": "Valitse työn päättymispäivä",
      "Please select from date": "Valitse työn aloituspäivä",
      "Please enter maximum number of application": "Syötä työhakemuksien enimmäismäärä",
      "Please select category image": "Valitse toimialaa esittävä kuvake",
      "Please select Highest Degree": "Valitse korkein ammattitutkinto",
      "Please enter contact person number": "Syötä yhteyshenkilön numero",
      "Please enter contact person name": "Syötä yhteyshenkilön nimi",
      "Please enter Job Location": "Syötä työpaikan sijainti",
      "Please enter Compensation Amount": "Syötä korvauksen tai palkkion määrä",
      "Please enter Compensation Details": "Syötä korvauksen tai palkkion tiedot",
      "Please enter Job Duration": "Syötä työn kesto",
      "Please select employment details": "Valitse työsopimuksen tyyppi",
      ".png Images are not supported": ".png-kuvia ei voi ladata",
      "Please Enter Company Name": "Syötä yrityksen nimi",
      "Please Enter Company Website": "Syötä yrityksen verkkosivusto",
      "OK": "OK"
    },
    "LastL": {
      "Stored Messages": "Tallennetut viestit",
      "Unlocked By": "Avattu",
      "User Type": "Käyttäjätyyppi",
      "Search": "Haku",
      "Filter By": "Suodata",
      "Sort By": "Lajittele",
      "Experience": "Työkokemus",
      "Location Radius": "Sijaintisäde",
      "Matching Percentage": "Osumaprosentti",
      "Package Range": "Palkkahaarukka",
      "Passout Year": "Valmistumisvuosi",
      "Distance": "Etäisyys",
      "Search by Area/Street/City Name": "Hae alueen / kadun / kaupungin nimellä",
      "Please select Highest Degree completion year": "Valitse valmistumisvuosi"
    },
    "Lastest": {
      "NoAccount": "Eikö ole tiliä?",
      "Login": "Kirjaudu sisään",
      "Sec": "Sek",
      "Please Wait": "Ole hyvä ja odota.",
      "UpGradePlan": "Jos haluat nähdä hakijan, ole hyvä ja päivitä tilauksesi",
      "Monthly": "Kuukausittain",
      "ProfileUpdate": "Profiili päivitetty onnistuneesti",
      "NewCategory": "Lisää uusi ammatti",
      "Submit": "Hyväksy",
      "Unlocked": "Avattu",
      "First Round": "Ensimmäinen kierros",
      "Second Round": "Toinen kierros",
      "Selected": "Valittu",
      "Rejected": "Hylätty",
      "CandidateAlreadyExist": "Hakija on olemassa tällä listalla",
      "Monday": "maanantai",
      "Tuesday": "tiistai",
      "Wednesday": "keskiviikko",
      "Thursday": "torstai",
      "Friday": "perjantai",
      "Saturday": "lauantai",
      "Sunday": "sunnuntai",
      "LanguageAlert": "Sulje sovellus ja käynnistä se uudelleen vaihtaaksesi kielen suomeksi.",
      "Hire": "Ota töihin",
      "Hirerequest": "Olet luonut palkkaus- työhönottamispyynnön",
      "DeleteJob": "Haluatko varmasti poistaa tämän työn?",
      "Address": "Osoite",
      "Jobs": "työpaikkoja",
      "Login Successfully": "Onnistunut sisäänkirjautuminen",
      "Hours": "Tuntia",
      "Premium": "Premium",
      "Minutes": "Minuutit",
      "Invalid credentials": "Virheelliset kirjautumistiedot",
      "Status : Accepted": "Tila: Hyväksytty",
      "Status : Rejected": "Tila: Hylätty",
      "Unread": "Lukematon"
    },
    "Newtranslation": {
      "job": "Työpaikat,",
      "HireRequest": "Palkkaus ja työhönottamispyynnöt",
      "Timesheet": "Työaikakalenteri",
      "Message": "Viestit",
      "Unlocked by recruiter": "Avattu rekrytoijan toimesta",
      "Monthly Plan": "Kuukausisuunnitelma",
      "Half Yearly Plan": "Puolivuotissuunnitelma",
      "Yearly Plan": "Vuosisuunnitelma",
      "valid for": "voimassa",
      "days": "päivää",
      "Feature": "Sisältö",
      "Includescredits": "Sisältää 10 krediittiä kuukaudessa.",
      "Credits can be carried forward each month": "Krediitit voidaan siirtää seuraavalle kuukaudelle",
      "Upgrade Subcription": "Päivitä tilaus",
      "Newcandidate": "Uusi ehdokas",
      "No relevant candidate found": "Sopivaa ehdokasta ei löytynyt",
      "Years of": "Vuodet",
      "Candidates": "Ehdokkaat",
      "The payment has been canceled": "Maksu hylätty",
      "Applicant already exist": "Hakija on jo olemassa",
      "The payment method was setup successfully": "Maksutapa määritettiin onnistuneesti",
      "Your Hire Request has been sent successfully": "Työhönottamispyyntösi lähetetty onnistuneesti",
      "Slot Available": "Työpaikka saatavilla",
      "Task Description": "Tehtäväkuvaus",
      "Time Sheet Approved successfully": "Työaikakalenteri hyväksytty onnistuneesti",
      "Time Sheet disapproved": "Työaikakalenteri hylätty",
      "Search By Candidate title": "Hae hakijan ammatin mukaan",
      "This phone number doesn't exist": "Puhelinnumeroa ei löydy",
      "OTP send successfully": "OTP Kertakäyttöinen salasana lähetetty onnistuneesti",
      "Login Successfully": "Kirjautuminen onnistui",
      "Invalid Credentials": "Virheelliset kirjautumistiedot",
      "Enter Current Password": "Syötä olemassa oleva salasana",
      "Enter New Password": "Syötä uusi salasana",
      "Confirm New Password": "Vahvista uusi salasana",
      "Cancel": "Peruuta"
    },
    "YearMonth": {
      "Enter Valid Username": "Anna kelvollinen käyttäjätunnus",
      "Enter Valid Mobile Number": "Anna kelvollinen matkapuhelinnumero",
      "Do you really want to Enable?": "Haluatko varmasti ottaa käyttöön?",
      "Do you really want to Disable?": "Haluatko varmasti poistaa käytöstä?",
      "Do you really want to Deactivate?": "Haluatko varmasti poistaa käytöstä?",
      "Are you sure you want to delete this sub user?": "Haluatko varmasti poistaa tämän alakäyttäjän?",
      "Are you sure you want to make admin for this user?": "Haluatko varmasti tehdä tästä alakäyttäjästä adminin tälle pääkäyttäjälle?",
      "Do you really want to approve?": "Haluatko varmasti hyväksyä?",
      "Do you really want to delete?": "Haluatko varmasti poistaa?",
      "Email or mobile number already exists for another user Or Please enter the same email & mobile as the seeker.": "Sähköposti- tai matkapuhelinnumero on jo olemassa toisella käyttäjällä. Käytä toista puhelinnumeroa ja sähköpostiosoitetta.",
      "User already exists.": "Lisää uusi alakäyttäjä",
      "Enter Valid Website Url": "Lisää yrityksen verkkosivuosoite",
      "Enter Company Location": "Lisää yrityksen osoite",
      "Data Updated Sucessfully": "Päivitä yrityksen tiedot",
      "Company created Sucessfully": "Luo uusi yritys",
      "Client Name (with subuser)": "Asiakkaan nimi (alakäyttäjän kanssa)",
      "Profile(%)": "Profiili(%)",
      "Username": "Käyttäjätunnus",
      "Password": "Salasana",
      "Vacancies": "Avoimet työpaikat",
      "Describe here": "Kirjoita kuvaus",
      "Yes": "Kyllä",
      "No": "Ei",
      "Active": "Aktiivinen",
      "Deactive": "Ei käytössä",
      "Paid": "Maksettu",
      "Free": "VAPAA",
      "FREE": "VAPAA",
      "ByPass Is_True": "Maksun ohitus toteutunut",
      "True": "Totta",
      "False": "Virheellinen",
      "Subscription": "Tilaus",
      "Top-Up": "Täydennä",
      "User": "Käyttäjä",
      "created": "luotu",
      "succeeded": "onnistunut",
      "payment_failed": "maksu epäonnistui",
      "Revenue By Subscription": "Tulot kuukausitilauksien mukaisesti",
      "Revenue By Topup": "Tulot lisäkrediittien ostoista ",
      "OPEN": "AVAA",
      "sub_user": "Alakäyttäjä",
      "jobseeker": "Työnhakija",
      "CLOSED": "SULJETTU",
      "January": "Tammikuu",
      "February": "Helmikuu",
      "March": "Maaliskuu",
      "April": "Huhtikuu",
      "May": "Toukokuu",
      "June": "Kesäkuu",
      "July": "Heinäkuu",
      "August": "Elokuu",
      "September": "Syyskuu",
      "October": "Lokakuu",
      "November": "Marraskuu",
      "December": "Joulukuu",
      "Today": "Tänään",
      "Yesterday": "Eilen",
      "a minute ago": "minuutti sitten",
      "minutes ago": "minuuttia sitten",
      "hours ago": "tunteja sitten",
      "a hour ago": "tunti sitten",
      "a day ago": "päivä sitten",
      "days ago": "päivää sitten",
      "Do You Really Want To Delete Your Account": "Haluatko varmasti poistaa tilisi?",
      "phone, username already exist": "puhelinnumero ja / tai käyttäjätunnus on jo olemassa",
      "User does not exists": "Käyttäjää ei ole olemassa",
      "This phone number doesn't exist": "Tätä puhelinnumeroa ei ole olemassa",
      "Login Successfully": "Onnistunut sisäänkirjautuminen",
      "User is deactivated by admin": "Järjestelmänvalvoja on poistanut käyttäjän tilin",
      "Time sheet downloaded successfully, Please check your download folder": "Työaikakalenterin lataus onnistui. Tarkista latauskansiosi",
      "Space are not allowed": "Välilyönnit eivät ole sallittuja",
      "Are you sure you want to Transfer Admin Right?": "Haluatko varmasti siirtää järjestelmänvalvojan oikeuden toiselle henkilölle?",
      "Close": "Sulje",
      "certificate": "todistus",
      "Please correct the following errors.": "Korjaa seuraavat virheet:",
      "phone, email already exist": "puhelin ja/ tai sähköposti on jo olemassa",
      "email already exist": "sähköposti on jo olemassa",
      "phone already exist": "puhelinnumero on jo olemassa",
      "You have created a new sub user": "Olet luonut uuden alakäyttäjän",
      "You have updated a sub user's detail": "Olet päivittänyt alakäyttäjän tiedot",
      "Admin": "Järjestelmänvalvoja",
      "Sub-User": "alakäyttäjä",
      "Created": "Luotu",
      "Year": "vuosi",
      "OTP verified Successfully": "OTP varmistettu onnistuneesti",
      "Login": "Kirjaudu sisään",
      "Please Enter Current Password": "Syötä nykyinen salasana",
      "Please Enter New Password": "Anna uusi salasana",
      "Password Does not Match": "Salasanat eivät täsmää",
      "You cannot set the old password again": "Et voi asettaa vanhaa salasanaa uudelleen",
      "Password Reset": "Salasanan nollaus",
      "Thank You for connecting our support team. we'll get back to you soon": "Kiitos yhteydenotostasi asiakastukeemme. Otamme sinuun pian yhteyttä.",
      "All Companny": "Kaikki yritykset",
      "Dashboard": "Työpöytä",
      "Categories": "Toimialat",
      "Jobseekers": "Työnhakijat",
      "Job Seeker": "Työnhakijat",
      "Job Post Management": "Työpaikkojen hallinta",
      "Client Management": " Asiakashallinta",
      "By-pass Payment": "Ohitusmaksu",
      "Order History": "Tilaushistoria ",
      "Tickets": "Tiketit / Viestit",
      "Package": "Palvelupaketit",
      "Transaction History": "Tapahtumahistoria",
      "Logout": "Kirjaudu ulos",
      "Auto Approve": " Automaattinen hyväksyntä",
      "Data Is Loading": " Tietoja ladataan ",
      "Search By Category/Added By": " Hae kategorioiden / lisäysten mukaan  ",
      "Image": "Kuva",
      "Rows per page:": "Rivit per sivu  ",
      "Search Name/Email/Profile": " Hae nimi / sähköpostiosoite / profiili",
      "Search By Recruiter/Jobs": " Hae rekrytoijan / työpaikkojen mukaan ",
      "Search Client/Company Name": " Hae asiakkaan / yrityksen nimellä",
      "RECRUITER PLAN": "REKRYTOIJAN PALVELUPAKETIT ",
      "Monthly Plan": "Kuukausisuunnitelma ",
      "Credit": "Krediitti",
      "Feature": "Sisältö",
      "Includes 10 credits per month.": " Sisältää 10 kredittiiä kuukaudessa.",
      "Half Yearly Plan": "Puolivuotissuunnitelma",
      "Credits can be carried forward each month.": "Käyttämättömät krediitit voidaan siirtää seuraavalle kuukaudelle.",
      "Yearly Plan": "Vuosisuunnitelma ",
      "VISIBILITY": " NÄKYVYYS",
      "JOBSEEKER PLAN": " Työhakijan näkyvyys",
      "Package Amount": " Paketin hinta",
      "Enter Package Amount": "Syötä paketin hinta",
      "Plan validity": "Tilauksen voimassaolo ",
      "Enter Plan validity": "Anna tilauksen voimassaolo",
      "Plan token_credits": "Krediittien arvo ",
      "Enter Plan token_credits": "Syötä krediittien arvo",
      "Plan token_credits is_carryforward": "Käyttämättömät krediitit voidaan siirtää",
      "Plan Recharge Duration": "Jatkotilauksen kesto",
      "cancel": "peruuta",
      "Save": "Tallenna",
      "Filter Transaction": " Suodata tapahtuma",
      "Service": "Palvelu",
      "Amount": "Määrä",
      "Payment Status": "Maksun tilanne ",
      "Transaction Date": " Tapahtuman päivämäärä",
      "previous": "Edellinen",
      "Next": "Seuraava",
      "Total Records:": "Tietueet yhteensä:",
      "AutoApproval updated successfully": "Automaattinen hyväksyntä päivitetty onnistuneesti",
      "Upload category Image": "Lataa toimialan kuvake",
      "Categories tittle": "Toimiala",
      "Added Successfully": "Lisätty onnistuneesti",
      "Category Approved Successfully": "Toimiala hyväksytty onnistuneesti",
      "Category Deleted Successfully": "Toimiala poistettu onnistuneesti",
      "Updated Deleted Successfully": "Päivitys poistettu onnistuneesti",
      "Vacancy Disapproved": "Avoin työpaikka on hylätty",
      "Vacancy Verified Successfully": "Avoin työpaikka on varmistettu onnistuneesti",
      "Top-Up Successfully": "Täydennys onnistui",
      "On-boarded Client activated": "Sisäänkirjautunut asiakas aktivoitu",
      "On-boarded Client deactivated": "Sisäänkirjautunut asiakas diaktivoitu",
      "Activated Successfully": "Aktivoitu onnistuneesti",
      "Deactivated Successfully": "Deaktivoitu onnistuneesti",
      "Response Send": "Vastaus Lähetetty",
      "Credit Top up": "Krediittien lisäys",
      "On-boarded Clients Enable": "Sisäänkirjautuneet asiakkaat käytössä",
      "On-boarded Clients Disable": "Sisäänkirjautuneet asiakkaat pois käytöstä",
      "Credit for Premium": "Krediitti Premiumiin",
      "Amount for Premium": "Premiumin määrä",
      "Credit for Free": "Luotto veloituksetta",
      "Amount for Free": "Määrä veloituksetta",
      "Credit for vacancy": "Krediitit avoinna olevasta työpaikasta",
      "Unlock duration(in Days)": "Avatun hakemuksen kesto (päivissä)",
      "Unlock duration": "Avatun hakemuksen kesto",
      "Token Limits updated successfully": "Token : Krediitin arvo päivitetty onnistuneesti",
      "recruiter": "rekrytoija",
      "Recruiter": "Rekrytoija",
      "Search by Company name": "Hae yrityksen nimellä",
      "Add Company": "Lisää yritys",
      "Company Image": "Yrityksen logo",
      "Please upload file having extensions .jpeg/.jpg/.png only.": "Lataa tiedosto, jonka tunniste on VAIN .jpeg/.jpg/.png."
    }
  },
  "es": {
    "language": "Eesti",
    "tab": {
      "Home": "Avaleht",
      "Explore Jobs": "Vaata töökohti",
      "My Vacancies": "Minu vabad kohad",
      "Post": "Lisa uus",
      "Unlocked Profiles": "Avatud profiil",
      "My Profile": "Minu profiil"
    },
    "Home": {
      "Hey, There": "Tere!",
      "Hey,": "Tere,",
      "Credits": "Krediidid",
      "Search by Candidates/categories/Services": "Otsi kandidaatide / tegevusala/ teenuste järgi",
      "Job Categories": "Tegevusala",
      "Show More": "Näita rohkem",
      "Show Less": "Näita vähem",
      "Your Subscription Info": "Tellimuse teave",
      "Upgrade your subscription to fulfil your requirements with our best plans.": "Täienda oma tellimust, et see vastaks paremini vajadustele.",
      "Upgrade your Subscription": "Täienda oma tellimust",
      "0 %": "0 %",
      "Complete Your Profile": "Täida oma profiil",
      "Your profile is incomplete! Please complete your profile to find the right candidate for your job": "Teie profiil on puudulik! Palun täida oma profiil, et leida tööks õige kandidaat.",
      "Login Now": "Logi sisse",
      "Complete Now": "Täida kohe",
      "Unlocked Profiles": "Avatud profiil",
      "All Profiles": "Kõik profiilid",
      "Candidates Near Me": "Kandidaadid minu lähedal",
      "View All": "Vaata kõiki",
      "Recommended Candidates": "Soovitatud kandidaadid",
      "Add to a List": "Lisa nimekirja",
      "Create New List": "Loo uus nimekiri",
      "Add": "Lisa",
      "Select Round": "Vali voor",
      "Use my Current Location": "Kasuta minu praegust asukohta",
      "Recent Locations": "Viimased asukohad",
      "Within 5 KM Radius": "5 km raadiuses",
      "Within 10 KM Radius": "10 km raadiuses",
      "Within 50 KM radius": "50 km raadiuses",
      "Within 100 KM Radius": "100 km raadiuses",
      "No New Notification Found": "Uusi teateid ei leitud."
    },
    "BlackCard": {
      "Premium": "Premium",
      "Verified": "Kinnitatud",
      "About Me": "Minust",
      "Experience": "Kogemus",
      "Experience Required": "Nõutav kogemus",
      "Employment Type": "Töösuhte tüüp",
      "Available": "Saadaval",
      "Expected Salary": "Oodatav palk",
      "Skills": "Oskused",
      "Education": "Haridus",
      "Passout Year": "Lõpetamise aasta",
      "Licenses": "Litsentsid, load, tunnistused",
      "Contact Details Locked": "Kontaktandmed on lukus",
      "See Full Details": "Vaata kogu profiili",
      "Less Than 1 KM Away": "Vähem kui 1 km kaugusel",
      "KM Away": "KM kaugusel",
      "Availability for Part Time ": "Saadavus osalise tööajaga ",
      "You": "Sina",
      "Match": "Sobivus",
      "Add To A List": "Lisa nimekirja",
      "Create New List": "Loo uus nimekiri",
      "Select Vacancy": "Vali vaba ametikoht",
      "Add": "Lisa",
      "Select Round": "Vali voor",
      "Hire": "Värba",
      "Please unlock the profile first": "Ava kõigepealt profiil",
      "Please do Login/Sign up": "Palun logi sisse/registreeru",
      "Are you sure to unlock the profile": "Kas oled kindel, et soovid profiili avada?",
      "Write your lists": "Lisa oma nimekirjad"
    },
    "JobCategoriesdata": {
      "Candidate": "Kandidaat",
      "Candidates": "Kandidaadid"
    },
    "RecommendedComp": {
      "Verified": "Kinnitatud",
      "Available": "Saadaval",
      "Total Experience": "Töökogemus kokku",
      "Year": "Aasta",
      "Years": "Aastad",
      "Current Location": "Praegune asukoht",
      "Miles Away": "Miili kaugusel",
      "Package": "Palk",
      "Match": "Sobivus",
      "% Match": "% sobivus",
      "Last seen": "Viimati nähtud",
      "ago": "tagasi",
      "KM Away": "KM kaugusel"
    },
    "UnlockedProfileComp": {
      "KM": "KM",
      "Match": "Sobivus",
      "Unlocked on": "Lukustatud",
      "Total Experience": "Kogemus kokku",
      "Current Location": "Praegune asukoht"
    },
    "Message": {
      "Filters": "Filtrid",
      "Search by Candidates/Keyword": "Otsi kandidaatide/võtmesõna järgi",
      "Messages": "Sõnumid",
      "No Messages Found": "Sõnumeid ei leitud",
      "You": "Sina",
      "jobseeker": "Tööotsija",
      "Today": "Täna"
    },
    "SearchEvents": {
      "Subscription Details": "Tellimuse üksikasjad",
      "Looking for": "Otsin",
      "Select Required Skills": "Vali nõutavad oskused",
      "Employment Type": "Töösuhte tüüp",
      "Part Time": "Osaline tööaeg",
      "Full Time": "Täistööaeg",
      "Contract": "Leping",
      "Gender": "Sugu",
      "Male": "Mees",
      "Female": "Naine",
      "Experience": "Kogemus",
      "Years": "Aastad",
      "Maximum Distance": "Maksimaalne kaugus",
      "Km": "Km",
      "Age": "Vanus",
      "Salary": "Palk",
      "K": "K",
      "Candidate Type": "Kandidaadi tüüp",
      "Frees": "Vaba",
      "Verified": "Kinnitatud",
      "Premium": "Premium",
      "Search Now": "Otsi nüüd",
      "Add To A List": "Lisa nimekirja",
      "Create New List": "Loo uus nimekiri",
      "Select Vacancy": "Vali töökoht",
      "Free": "Tasuta",
      "Current Plan": "Praegune plaan",
      "VIP": "VIP"
    },
    "UnlockedPro": {
      "Available": "Saadaval",
      "Unlocked Profiles": "Avatud profiilid",
      "Search by Candidate Name": "Otsi kandidaadi nime järgi",
      "Filters": "Filtrid",
      "Verified": "Kinnitatud",
      "Available 20 Hrs/week": "Saadaval 20 tundi/nädalas",
      "Total Experience": "Kogemus kokku",
      "Years": "Aastad",
      "Current Location": "Praegune asukoht",
      "km": "km",
      "Package": "Palk",
      "Match": "Sobivus",
      "Unlocked on": "Avatud",
      "Add to a List": "Lisa nimekirja",
      "Create New List": "Loo uus nimekiri",
      "No Data Found": "Andmeid ei leitud"
    },
    "CreditHistory": {
      "Credit balance": "Krediidi saldo",
      "Available Credits": "Saadaval olevad krediidid",
      "Used Credits": "Kasutatud krediidid",
      "Total Credits": "Kokku krediidid",
      "Subscription Details": "Tellimuse üksikasjad",
      "Plan": "Plaan",
      "Days Left": "Päevi jäänud",
      "days Validity": "päeva kehtivusaega",
      "Purchased Date": "Ostukuupäev",
      "Validity": "Kehtivus",
      "days": "päeva",
      "Amount": "Summa",
      "Payment Mode": "Makseviis",
      "Credit History": "Krediidiajalugu",
      "Recharge Credit": "Lisa krediiti",
      "Select no. of Credits": "Vali krediitide arv",
      "Credits": "Krediidid",
      "Pay € 30": "Maksa 30€",
      "Premium": "Premium",
      "Credit Balance & History": "Krediidisaldo ja ajalugu",
      "Upgrade Plan": "Uuenda tellimus",
      "Credit Top-up": "Krediidi lisamine",
      "Unlocked By": "Avatud",
      "End Date": "Lõppkuupäev"
    },
    "PaymentSuccesfull": {
      "Thank you !": "Aitäh !",
      "Your Payment was Successfull": "Sinu makse õnnestus",
      "Your Transcation ID: 1236589742 & Your plan Renews on 15/04/2021": "Tehingu ID: 1236589742. Plaan uuendatakse 15.04.2021",
      "Post Vacancy": "Avalda vaba töökoht",
      "Explore Candidates": "Tutvu kandidaatidega"
    },
    "Subscription": {
      "Free": "Tasuta",
      "Vip": "VIP",
      "Premium": "Premium",
      "Current Plan": "Praegune plaan",
      "€ 300/pm": "300€/kuu",
      "Upgrade to": "Uuenda",
      "€ 499/pm": "499€/kuu",
      "Upgrade Now": "Uuenda kohe",
      "Credit": "Krediit",
      "Payment successful": "Makse õnnestus"
    },
    "RecommBlackCard": {
      "Select Vacancy": "Vali vaba töökoht",
      "Add to a List": "Lisa nimekirja",
      "Create New List": "Loo uus nimekiri",
      "Add": "Lisa",
      "Select Round": "Vali voor",
      "10 %": "10 %",
      "%": "%",
      "Match": "Sobivus",
      "Hire": "Värba"
    },
    "Hiring": {
      "Select Vacancy": "Vali töökoht",
      "Hiring Details": "Värbamise üksikasjad",
      "Vacancy Details": "Vakantsi üksikasjad",
      "Job Duration": "Töö kestus",
      "Submit": "Esita",
      "Add to a List": "Lisa nimekirja",
      "Create New List": "Loo uus nimekiri",
      "Add": "Lisa"
    },
    "UnlockedProfileBlackScreen": {
      "Add to a List": "Lisa nimekirja",
      "Create New List": "Loo uus nimekiri",
      "Add": "Lisa",
      "Done": "Valmis",
      "Select Round": "Vali voor",
      "Match": "Sobivus",
      "Hire": "Värba"
    },
    "CandidateNearMapScreen": {
      "Less than 1 Miles away": "Vähem kui 1 miili kaugusel",
      "Miles away": "Miili kaugusel",
      "10% Match": "10% sobivus",
      "% Match": "% sobivus",
      "View Profile": "Vaata profiili",
      "10%": "10%",
      "%": "%"
    },
    "SearchScreen": {
      "Search by candidates title, industry Title": "Otsi kandidaadi tiitli, tegevusala järgi",
      "Filters": "Filtrid",
      "Experience Between": "Kogemus vahemikus",
      "Year": "Aasta",
      "Within": "Raadius",
      " mi of Radius": " mi raadiuses",
      "Salary Between": "Palk vahemikus",
      "K": "K",
      "No Data Found": "Andmed puuduvad",
      "Select Vacancy ": "Vali vabade ametikohtade hulgast",
      "Select Round": "Vali voor",
      "Add to a List": "Lisa nimekirja",
      "Create New List": "Loo uus nimekiri",
      "Add": "Lisa",
      "10 % Add": "10 % Lisa",
      "%": "%",
      "Match": "Sobivus",
      "Hire": "Värba"
    },
    "EditProfile": {
      "Upload image": "Lae pilt üles",
      "Personal Information": "Isiklik informatsioon",
      "Submit": "Kinnita"
    },
    "HireDetails": {
      "Hires": "Tööle valitud isikud",
      "Time Sheet": "Tööaja tabel",
      "View Full Profile": "Vaata täielikku profiili",
      "OnGoing Job": "Käimasolev töö",
      "Hours Worked": "Töötatud tundide arv",
      "Hours": "Tundi",
      "Job Duration": "Töö kestus",
      "Hired Date": "Palkamise kuupäev",
      "Verified": "Kinnitatud",
      "All times are in your local your local (PTS)": "Kõik ajad on sinu ajavööndis"
    },
    "SuccessfulHire": {
      "Your Hire Request to ramesh has been sent Successfully": "Palga taotlus ramesh'le on edukalt saadetud",
      "Resume Hiring": "Jätka värbamist"
    },
    "ApplicantsDetails": {
      "Filters": "Filtrid:",
      "Availability for Part Time": "Saadaval osalise tööajaga",
      "99% ": "99% ",
      "match": "Sobivus",
      "Hire": "Värba"
    },
    "NoDataFound": {
      "Opps ! ": "Vabandust ! ",
      "No Data Found ": "Andmeid ei leitud ",
      "Post New Vacancy": "Avalda uus töökoht"
    },
    "PostNewVacancy": {
      "Confirm to Reject": "Kinnita tagasilükkamine",
      "Confirm to Accept": "Kinnita vastuvõtmine",
      "Select Required Skills": "Vali nõutavad oskused",
      "Job Description": "Töö kirjeldus",
      "Select Required Experience": "Vali nõutav kogemus",
      "No Of Vacancies": "Töökohtade arv",
      "Job Duration(In Days)": "Töö kestvus (päevades)",
      "Enter Compensation Amount": "Sisesta töötasu summa",
      "Post Vacancy": "Avalda töö uus töökoht",
      "Enter Job Title": "Sisesta ametinimetus",
      "Company Name (Please Update Your Profile)": "Firma nimi (Palun uuenda oma profiili)",
      "Select Job Category": "Vali tegevusala",
      "Job Details": "Töö detailiandmed",
      "Is this vacancy Urgent ? ": "Kas see töökoht vajab kiiresti täitmist?",
      "Is Liscense Requrired ?": "Kas on vajalik litsents, luba?",
      "Employment Details": "Töösuhte tüüp",
      "Compensation Details": "Töötasu detailid",
      "Company Details": "Firma detailid",
      "Work Location": "Töö asukoht",
      "Contact Person Name": "Kontaktisiku nimi",
      "Contact Number": "Kontaktisiku telefoninumber",
      "Select Highest Degree": "Vali kõrgeim omandatud haridus",
      "Highest Degree Completion Year": "Kõrgeima haridusastme lõpetamise aasta",
      "Maximum Application Required": "Maksimaalne avalduste arv",
      "Enter Search Radius (In Meters)": "Sisesta otsingu raadius (meetrites)",
      "Enter Short Description": "Sisesta lühikirjeldus",
      "Qualification Required ": "Nõutavad kvalifikatsioonid",
      "Post Setting": "Postituse seaded",
      "Review & Post Vacancy": "Vaata üle ja avalda töökoht"
    },
    "PostSuccessFulMessage": {
      "Edit Vacancy": "Muuda töökoha andmeid",
      "Post SuccessFul": "Töökoha avaldamine õnnestus",
      "Vacancy Posted Successfully !": "Töökoht on avaldatud!",
      "Post Another Vacancy": "Avalda veel üks töökoht"
    },
    "MyProfile": {
      "Welcome to Catzapp": "Tere tulemast Catzapp'i",
      "Login": "Logi sisse",
      "Log Out": "Logi välja",
      "My Profile": "Minu profiil",
      "Create an Account": "Loo konto",
      "My Subscriptions": "Minu tellimused",
      "My Lists": "Minu nimekirjad",
      "My Vacancies": "Minu tööpakkumised",
      "My Unlocked Candidates": "Minu avatud kandidaadid",
      "Sub Users": "Alamkasutajad",
      "Settings": "Seaded",
      "Language": "Keel",
      "Alert/Notifications": "Teated",
      "Privacy & Policies": "Privaatsuspoliitika",
      "Contact Support": "Kontakteeru toeosakonnaga",
      "Update Profile": "Uuenda profiili",
      "Upload Image": "Lae pilt üles",
      "Personal Information": "Isiklik teave",
      "Submit": "Esita",
      "Upolad Your Company Image": "Laadi oma ettevõtte pilt üles",
      "About Your Company": "Sinu ettevõttest",
      "Company Location": "Ettevõtte asukoht",
      "Company Website": "Ettevõtte veebisait",
      "Company Name": "Ettevõtte nimi",
      "Add Your Company": "Lisa oma ettevõte",
      "Select Company": "Vali ettevõte",
      "Email": "E-post",
      "Last Name": "Perekonnanimi",
      "First Name": "Eesnimi",
      "My List Screen": "Minu nimekiri",
      "Remove": "Eemalda",
      "Select Language": "Vali keel"
    },
    "NoUserFound": {
      "Opps !": "Vabandust!",
      "No Sub User Found": "Alamkasutajaid ei leitud",
      "Create Sub User": "Loo alamkasutaja"
    },
    "NewSubUser": {
      "User Details": "Kasutaja andmed",
      "Enter User Name": "Sisesta kasutajanimi",
      "Enter First Name": "Sisesta eesnimi",
      "Enter Last Name": "Sisesta perekonnanimi",
      "Enter Email Address": "Sisesta e-posti aadress",
      "Enter Mobile Number": "Sisesta mobiiltelefoninumber",
      "Enter Password": "Sisesta salasõna",
      "Confirm Password": "Kinnita salasõna",
      "Update Sub User ": "Uuenda alamkasutajat",
      "Create Sub User ": "Loo alamkasutaja",
      "Enter Full Name": "Sisesta täisnimi"
    },
    "MyList": {
      "No Data Found": "Andmeid ei leitud",
      "Remove": "Eemalda",
      "Available": "Saadaval"
    },
    "Settings": {
      "Email Notification": "E-kiri",
      "Notification": "Teade",
      "Update Password": "Uuenda parool"
    },
    "PrivacyPolicies": {
      "PRIVACY POLICY": "PRIVAATSUSPOLIITIKA",
      "Welcome to Recruiter": "Tere tulemast Recruiter'sse",
      "1. Introduction": "1. Sissejuhatus",
      "2. Definitions": "2. Määratlused",
      "3. Information Collection and Use": "3. Teabe kogumine ja kasutamine",
      "4. Types of Data Collected ": "4. Kogutud andmete tüübid ",
      "5. Types of Data Collected": "5. Kogutud andmete tüübid"
    },
    "ContactSupport": {
      "Submit": "Saada"
    },
    "MyListsScreen": {
      "Required": "Nõutud",
      "Immediately": "Kohe",
      "Total Experience": "Kogemus",
      "Years": "Aastad",
      "Year": "Aasta",
      "Work Location": "Töökoha asukoht",
      "Package": "Palk"
    },
    "PlayListBlackCard": {
      "Select Vacancy": "Vali vabade töökohtade hulgast",
      "Select Round": "Vali voor",
      "Add to a List ": "Lisa nimekirja",
      "Create New List ": "Loo uus nimekiri",
      "Add": "Lisa",
      "Done": "Valmis",
      "Match": "Sobivus",
      "Add Your List": "Lisa oma nimekiri"
    },
    "SignUp": {
      "Enter Your Name": "Sisesta oma nimi",
      "Enter Your Phone Number": "Sisesta oma telefoninumber",
      "Enter Your Email": "Sisesta oma e-posti aadress",
      "Enter Password": "Sisesta salasõna",
      "Set a password": "Määra salasõna",
      "Confirm password ": "Kinnita salasõna",
      "Confirm": "Kinnita"
    },
    "LoginPassword": {
      "Login": "Logi sisse",
      "Forgot Password": "Unustasid salasõna?",
      "Don't have an account?": "Kas sul pole kontot?",
      "SignUp": "Registreeri",
      "SKIP": "JÄTA VAHELE",
      "Please Enter MobileNumber & Password": "Sisesta oma telefoni number ja salasõna",
      "Please Enter Your Mobile Number": "Sisesta oma telefoni number",
      "Please Enter Your Password": "Sisesta oma salasõna",
      "Please Enter Valid Password": "Sisesta kehtiv salasõna",
      "Please enter your mobile number to complete the login process.": "Palun sisesta oma telefoni number, et logimine lõpule viia.",
      "Enter Your Mobile Number": "Sisesta oma telefoni number",
      "Enter Your Password": "Sisesta oma salasõna",
      "Login to Catzapp Recruiter": "Logi sisse Catzapp Recruiter'sse",
      "OTP": "Ühekordne salasõna (OTP)"
    },
    "ChangePassword": {
      "Please Enter Your New Password & Confirm Password": "Sisesta uus salasõna ja kinnita see",
      "Please Enter Your New Password": "Sisesta oma uus salasõna",
      "Please Enter Valid password": "Sisesta sobiv salasõna",
      "Please Enter Confirm Password": "Sisesta kinnitatud salasõna",
      "Password dosen't Match": "Salasõnad ei ühti",
      "Reset Your Password": "Lähtesta oma salasõna",
      "Set a password": "Määra salasõna",
      "Confirm password": "Kinnita salasõna",
      "Enter Your New password": "Sisesta oma uus salasõna",
      "Confirm Your New password": "Kinnita oma uus salasõna"
    },
    "ResetPassword": {
      "Please Enter Mobile Number": "Sisesta mobiiltelefoni number",
      "Submit OTP": "Kinnita ühekordne salasõna (OTP)",
      "Please Enter Otp": "Sisesta ühekordne salasõna (OTP)",
      "We Have sent One Time Password to": "Me oleme saatnud ühekordse salasõna (OTP) aadressile"
    },
    "interOtp": {
      "we have sent an OTP to": "Me oleme saatnud ühekordse salasõna (OTP) aadressile",
      "Did Not Receive Code Yet?": "Pole veel koodi saanud?",
      "Resend OTP": "Saada ühekordne salasõna (OTP) uuesti",
      "Please Enter OTP": "Sisesta ühekordne salasõna (OTP)",
      "Submit": "Esita"
    },
    "forgetPassword": {
      "Send OTP": "Saada ühekordne salasõna (OTP)",
      "Get Password": "Hangi salasõna",
      "Enter Mobile Number": "Sisesta mobiilinumber",
      "Please enter your mobile number to generate OTP": "Palun sisesta oma mobiiltelefoni number, et genereerida ühekordne salasõna (OTP)",
      "Please Enter Your Mobile Number": "Sisesta oma mobiiltelefoni number"
    },
    "myVacancy": {
      "Search by Job Title": "Otsi ametikoha järgi",
      "My Vacancies": "Minu töökohad",
      "No Data Found": "Andmeid ei leitud",
      "Applications": "Taotlused",
      "Application": "Taotlus",
      "Edit": "Muuda",
      "Delete": "Kustuta",
      "Required": "Nõutav",
      "Immediately": "Kohe",
      "Posted": "Avaldatud",
      "ago": "tagasi",
      "Applicants": "Kandidaadid",
      "Hire": "Värba",
      "Apply Filters": "Rakenda filtreid",
      "Clear All": "Kustuta kõik",
      "Make Admin": "Muuda administraatoriks"
    },
    "new": {
      "About Company": "Ettevõtte info",
      "Latter": "Viimane",
      "All": "Kõik",
      "Not Premium": "MItte-premium",
      "Premium": "Premium",
      "Buy Credit to Unlock the Candidates and Proceed further with the process to send messages, chat or call.": "Osta krediiti kandidaatide andmete avamiseks ja vestluseks ning sõnumite saatmiseks või kõnedeks .",
      "Are you sure you want to delete this chat?": "Kas oled kindel, et soovid selle vestluse kustutada?",
      "Recommended Profiles": "Soovitatud profiilid",
      "Map View": "Kaardi vaade",
      "List View": "Loendi vaade",
      "Read": "Loe",
      "2 Credits will get deducted. Are you sure to unlock the profile?": "2 krediiti arvatakse maha. Kas olete kindel, et soovid profiili avada?",
      "Subscribe Now": "Telli kohe",
      "Please unlock profile first": "Ava kõigepealt profiil",
      "You do not have a login yet, Please do login/Signup to continue.": "Sa ei ole veel sisse logitud, jätkamiseks loge sisse / registreeru.",
      "Signup/Login": "Registreeru/Logi sisse",
      "Welcome to Catzapp": "Tere tulemast Catzapp'i",
      "Create Account": "Konto loomine",
      "Or": "või",
      "Sign In": "Logi sisse",
      "Skip": "Jäta vahele",
      "Yes": "Jah",
      "No": "Ei",
      "Part Time": "Osaline tööaeg",
      "Full Time": "Täistööaeg",
      "Contract": "Lepingu alusel",
      "Daily": "Päevane",
      "Weekly": "Nädalane",
      "Monthly": "Kuine",
      "Yearly": "Aastane",
      "Upload Category Image": "Laadi üles tegevusala pilt",
      "From Date": "Alguse kuupäev",
      "To Date": "Lõpetamise kuupäev",
      "Selected": "Valitud",
      "Rejected": "Tagasi lükatud",
      "First Round": "Esimene voor",
      "Second Round": "Teine voor",
      "Type Message": "Kirjuta sõnum",
      "Subject": "Teema",
      "Enter your Description": "Sisesta kirjeldus",
      "Please Unlock Profile First": "Ava kõigepealt profiil",
      "Please Select Vacancy": "Vali vaba töökoht",
      "Please Select FroVm Date": "Palun töö alguse kuupäev",
      "Please Select To Date": "Vali töö lõpu kuupäev",
      "Please Enter Details": "Palun sisesta detailandmed",
      "Please Enter valid Email": "Palun sisesta kehtiv e-posti aadress",
      "Passwords should be a minimum of eight characters in length": "Salasõna peaks olema vähemalt kaheksa tähemärgi pikkune",
      "Please Confirm Your Password": "Palun kinnita oma salasõna",
      "Password Does Not Match": "Salasõnad ei ühti"
    },
    "FinalFinal": {
      "You have created a hire request": "Oled loonud töölevõtu taotluse",
      "Hire request already exists": "Töölevõtu taotlus on juba olemas",
      "Please Upload Image to add new categories": "Uute tegevusalade lisamiseks laadi üles pilt",
      "Want to post vacancy please upgrade subscrption": "Kui soovid lisada vaba töökoha, uuenda tellimust",
      "Please enter number of vacancy": "Sisesta vabade töökohtade arv",
      "Please enter search radius": "Sisesta otsingu raadius",
      "Enter Your Email Address": "Sisesta e-posti aadress",
      "Enter Your Phone Number": "Sisesta telefoninumber",
      "Please Enter Title": "Sisesta pealkiri",
      "Please Enter Description": "Sisesta kirjeldus",
      "Enter Your First Name": "Sisesta eesnimi",
      "Enter Your Last Name": "Sisesta perekonnanimi",
      "Password must be minimum of 8 character": "Salasõna peab olema vähemalt 8 tähemärki pikk",
      "Enter Confirm Password": "Sisesta salasõna uuesti",
      "Enter Valid Password (password must be combination of letters, numbers and special character": "Sisesta kehtiv salasõna (salasõna peab olema tähtede, numbrite ja erimärkide kombinatsioon)",
      "Please Enter First Name": "Sisesta eesnimi",
      "Please Enter Last Name": "Sisesta perekonnanimi",
      "Please Enter Email": "Sisesta e-posti aadress",
      "Please Select or Add a Company": "Vali või lisa ettevõte",
      "Please Select Profile Picture": "Vali profiilipilt",
      "Please enter Job Title": "Sisesta ametinimetus",
      "Please select Company": "Vali ettevõte",
      "Please Select Company Image": "Vali ettevõtte pilt",
      "Please Enter Company Detail": "Sisesta ettevõtte detailandmed",
      "Please select Job Category": "Vali tegevusala",
      "Please select required Experience": "Vali  nõutav kogemus",
      "Please enter Job Description": "Sisesta töö kirjeldus",
      "Please enter short description": "Sisesta lühike kirjeldus",
      "Please select Skills": "Vali oskused",
      "Please select urgent vacancy": "Vali, kas koht vajab kiiret täitmist",
      "Please select liscense required": "Vali, kas litsents/luba on nõutav",
      "Please select to date": "Vali kuupäev",
      "Please select from date": "Vali töö alguse kuupäev",
      "Please enter maximum number of application": "Sisesta maksimaalne soovitav taotluste arv",
      "Please select category image": "Vali tegevusala pilt",
      "Please select Highest Degree": "Palun vali kõrgeim omandatud haridus",
      "Please enter contact person number": "Sisesta kontaktisiku telefoninumber",
      "Please enter contact person name": "Sisesta kontaktisiku nimi",
      "Please enter Job Location": "Ssisesta töö asukoht",
      "Please enter Compensation Amount": "Sisesta töötasu summa",
      "Please enter Compensation Details": "Sisesta töötasu detailandmed",
      "Please enter Job Duration": "Sisesta töö kestus",
      "Please select employment details": "Vali töösuhte tüüp",
      ".png Images are not supported": ".png-piltide lisamine ei ole lubatud",
      "Please Enter Company Name": "Sisesta ettevõtte nimi",
      "Please Enter Company Website": "Sisesta ettevõtte veebisait",
      "OK": "OK"
    },
    "LastL": {
      "Stored Messages": "Salvestatud sõnumid",
      "Unlocked By": "Avatud",
      "User Type": "Kasutajatüüp",
      "Search": "Otsi",
      "Filter By": "Filtreeri",
      "Sort By": "Sorteeri",
      "Experience": "Kogemus",
      "Location Radius": "Asukoha raadius",
      "Matching Percentage": "Vastavusprotsent",
      "Package Range": "Palgavahemik",
      "Passout Year": "Lõpetamise aasta",
      "Distance": "Kaugus",
      "Search by Area/Street/City Name": "Otsi piirkonna/tänava/linna nime järgi",
      "Please select Highest Degree completion year": "Vali kõrgeima haridusastme lõpetamise aasta"
    },
    "Lastest": {
      "NoAccount": "Konto puudub?",
      "Login": "Logi sisse",
      "Sec": "Sek.",
      "Please Wait": "Palun oota",
      "UpGradePlan": "Kandidaadi profiili vaatamiseks palun uuenda plaan",
      "Monthly": "Igakuine",
      "ProfileUpdate": "Profiili uuendamine õnnestus",
      "NewCategory": "Lisa tegevusala",
      "Submit": "Kinnita",
      "Unlocked": "Avatud",
      "First Round": "Esimene voor",
      "Second Round": "Teine voor",
      "Selected": "Valitud",
      "Rejected": "Tagasilükatud",
      "CandidateAlreadyExist": "Kandidaat on juba lisatud valitud loendisse",
      "Monday": "esmaspäev",
      "Tuesday": "teisipäev",
      "Wednesday": "kolmapäev",
      "Thursday": "neljapäev",
      "Friday": "reede",
      "Saturday": "laupäev",
      "Sunday": "pühapäev",
      "LanguageAlert": "Palun sulge ja taaskäivita rakendus,et  keele vahetus rakenduks",
      "Hire": "Värba",
      "Hirerequest": "Olete loonud töölevõtu taotluse",
      "DeleteJob": "Kas olete kindel, et soovid eemaldada selle töö?",
      "Address": "Aadress",
      "Jobs": "Töökohad",
      "Login Successfully": "Sisselogimine õnnestus",
      "Hours": "Tunnid",
      "Premium": "Premium",
      "Minutes": "Minutid",
      "Invalid credentials": "Kehtetud mandaadid",
      "Status : Accepted": "Staatus: Vastu võetud",
      "Status : Rejected": "Staatus: Tagasi lükatud",
      "Unread": "Lugemata"
    },
    "Newtranslation": {
      "job": "Töökohad",
      "HireRequest": "Värbamine ja töölevõtu taotlused",
      "Timesheet": "Tööajatabel",
      "Message": "Sõnum",
      "Unlocked by recruiter": "Avatud värbaja poolt",
      "Monthly Plan": "Kuuplaan",
      "Half Yearly Plan": "Poolaasta plaan",
      "Yearly Plan": "Aastaplaan",
      "valid for ": "kehtib ",
      "days": "päeva",
      "Feature": "Omadus",
      "Includescredits": "Sisaldab 10 krediiti kuus.",
      "Credits can be carried forward each month": "Krediite saab iga kuu edasi kanda",
      "Upgrade Subcription": "Tellimuse täiendamine",
      "Newcandidate": "Autojuhi ametikohale kandideeris uus kandidaat. kontrolli kohe",
      "No relevant candidate found": "Ühtegi sobivat kandidaati ei leitud",
      "Years of": "Aastaid",
      "Candidates": "Kandidaadid",
      "The payment has been canceled": "Makse on tühistatud",
      "Applicant already exist": "Taotleja on juba olemas",
      "The payment method was setup successfully": "Makseviisi seadistamine õnnestus",
      "Your Hire Request has been sent successfully": "Teie töölevõtu taotlus on edukalt saadetud",
      "Slot Available": "Töökoht saadaval",
      "Task Description": "Ülesande kirjeldus",
      "Time Sheet Approved successfully": "Ajatabel edukalt kinnitatud",
      "Time Sheet disapproved": "Ajatabeli tagasilükkamine õnnestus",
      "Search By Candidate title": "Otsi kandidaati ameti järgi",
      "This phone number doesn't exist": "Seda telefoninumbrit ei eksisteeri",
      "OTP send successfully": "Ühekordse salasõna (OTP) saatmine õnnestus",
      "Login Successfully": "Sisselogimine õnnestus",
      "Invalid Credentials": "Kehtetu kasutajatunnus/salasõna",
      "Enter Current Password": "Sisesta kehtiv salasõna",
      "Enter New Password": "Sisesta uus salasõna",
      "Confirm New Password": "Kinnita uus salasõna",
      "Cancel": "Katkesta"
    },
    "YearMonth": {
      "Enter Valid Username": "Sisesta kehtiv kasutajanimi",
      "Enter Valid Mobile Number": "Sisesta kehtiv mobiiltelefoni number",
      "Do you really want to Enable?": "Kas oled kindel, et soovid  võtta kasutusele?",
      "Do you really want to Disable?": "Kas oled kindel, et soovid keelata?",
      "Do you really want to Deactivate?": "Kas oled kindel, et soovid deaktiveerida?",
      "Are you sure you want to delete this sub user?": "Kas oled kindel, et soovid alamkasutaja kustutada?",
      "Are you sure you want to make admin for this user?": "Kas oled kindel, et soovid kasutaja kasutaja määrata administraatoriks?",
      "Do you really want to approve?": "Kas oled kindel, et soovid kinnitada?",
      "Do you really want to delete?": "Kas oled kindel, et soovid kustutada?",
      "Email or mobile number already exists for another user Or Please enter the same email & mobile as the seeker.": "E-mail või telefon on juba olemas teisel kasutajal. Kasuta teist aadressi ja telefoni.",
      "User already exists.": "Lisa uus alamkasutaja",
      "Enter Valid Website Url": "Lisa ettevõtte veebisait",
      "Enter Company Location": "Lisa ettevõtte aadress",
      "Data Updated Sucessfully": "Värskenda ettevõtte teavet",
      "Company created Sucessfully": "Loo uus ettevõte",
      "Client Name (with subuser)": "Kliendi nimi (alamkasutajaga)",
      "Profile(%)": "Profiil(%)",
      "Username": "Kasutajanimi",
      "Password": "Salasõna",
      "Vacancies": "Vabad töökohad",
      "Describe here": "Kirjeldus",
      "Yes": "Jah",
      "No": "No",
      "Active": "Aktiivne",
      "Deactive": "Mitteaktiivne",
      "Paid": "Makstud",
      "Free": "TASUTA",
      "FREE": "TASUTA",
      "ByPass Is_True": "Möödasõidumakse õnnestus",
      "True": "Õige",
      "False": "Vale",
      "Subscription": "Tellimus",
      "Top-Up": "Täienda",
      "User": "Kasutaja",
      "created": "loodud",
      "succeeded": "õnnestus",
      "payment_failed": "makse ebaõnnestus",
      "Revenue By Subscription": "Tulu tellimuse alusel",
      "Revenue By Topup": "Tulu lisakrediidi ostmisest",
      "OPEN": "AVATUD",
      "sub_user": "Alamkasutaja",
      "jobseeker": "Tööotsija",
      "Job Seeker": "Tööotsija",
      "CLOSED": "SULETUD",
      "January": "jaanuar",
      "February": "veebruar",
      "March": "märts",
      "April": "aprill",
      "May": "mai",
      "June": "juuni",
      "July": "juuli",
      "August": "august",
      "September": "september",
      "October": "oktoober",
      "November": "november",
      "December": "detsember",
      "Today": "Täna",
      "Yesterday": "eile",
      "a minute ago": "minut tagasi",
      "minutes ago": "minutit tagasi",
      "hours ago": "tundi tagasi",
      "a hour ago": "tund tagasi",
      "a day ago": "päev tagasi",
      "days ago": "päeva tagasi",
      "Do You Really Want To Delete Your Account": "Kas oled kindel, et soovid konto kustutada?",
      "phone, username already exist": "telefon, kasutajanimi on juba olemas",
      "User does not exists": "Kasutajat ei eksisteeri",
      "This phone number doesn't exist": "Seda telefoninumbrit ei eksisteeri",
      "Login Successfully": "Sisselogimine õnnestus",
      "User is deactivated by admin": "Administraator on kasutaja deaktiveerinud",
      "Time sheet downloaded successfully, Please check your download folder": "Tööajatabel on edukalt alla laaditud. Kontrolli oma allalaadimiskausta",
      "Space are not allowed": "Tühikud ei ole lubatud",
      "Are you sure you want to Transfer Admin Right?": "Kas oled kindel, et soovid administraatoriõiguse eemaldada?",
      "Close": "Sulge",
      "certificate": "tunnistus",
      "Please correct the following errors.": "Paranda järgmised vead.",
      "phone, email already exist": "telefon, e-post on juba olemas",
      "email already exist": "e-post on juba olemas",
      "phone already exist": "telefon on juba olemas",
      "You have created a new sub user": "Oled loonud uue alamkasutaja",
      "You have updated a sub user's detail": "Oled värskendanud alamkasutaja andmeid",
      "Admin": "Admin",
      "Sub-User": "Alamkasutaja",
      "Created": "Loodud",
      "Year": "aasta",
      "OTP verified Successfully": "Ühekordse salasõna (OTP) kinnitamine õnnestus",
      "Login": "Logi sisse",
      "Please Enter Current Password": "Sisesta praegune salasõna",
      "Please Enter New Password": "Sisesta uus salasõna",
      "Password Does not Match": "Salasõna ei ühti",
      "You cannot set the old password again": "Vana salasõna uuesti määrata ei ole võimalik",
      "Password Reset": "Salasõna lähtestamine",
      "Thank You for connecting our support team. we'll get back to you soon": "Täname, et pöördusid meie klienditoe poole. Võtame sinuga varsti ühendust",
      "All Companny": "Kogu ettevõte",
      "Dashboard": "Töölaud",
      "Categories": "Tegevusalad",
      "Jobseekers": "Tööotsijad",
      "Job Post Management": "Töökohtade haldus",
      "Client Management": "Kliendihaldus",
      "By-pass Payment": "Möödasõidumakse",
      "Order History": "Tellimuste ajalugu",
      "Tickets": "Piletid",
      "Package": "Pakett",
      "Transaction History": "Tehingu ajalugu",
      "Logout": "Logi välja",
      "Auto Approve": "Automaatne kinnitamine",
      "Data Is Loading": "Andmeid laaditakse",
      "Search By Category/Added By": "Otsi kategooria/lisatud poolt",
      "Image": "Pilt",
      "Rows per page:": "Ridu lehel:",
      "Search Name/Email/Profile": "Otsi nime/e-posti/profiili",
      "Search By Recruiter/Jobs": "Otsi värbaja/töökohtade järgi",
      "Search Client/Company Name": "Otsi kliendi/ettevõtte nime alusel",
      "RECRUITER PLAN": "VÄRBAJA PAKETID",
      "Monthly Plan": " Kuu tellimus",
      "Credit": "Krediit",
      "Feature": "Sisu",
      "Includes 10 credits per month.": " Sisaldab 10 krediiti kuus.",
      "Half Yearly Plan": "Poolaasta tellimus",
      "Credits can be carried forward each month.": "Krediite saab iga kuu edasi kanda.",
      "Yearly Plan": " Aasta tellimus",
      "VISIBILITY": "NÄHTAVUS",
      "JOBSEEKER PLAN": "TÖÖOTSIJA PLAAN",
      "Package Amount": " Paketi hind",
      "Enter Package Amount": "Sisesta paketi hind",
      "Plan validity": "Tellimuse kehtivusaeg",
      "Enter Plan validity": "Siseste tellimuse kehtivusaeg",
      "Plan token_credits": "Krediitide arv",
      "Enter Plan token_credits": "Sisesta krediitide arv  ",
      "Plan token_credits is_carryforward": " Kasutamata krediite võib edasi nihutada",
      "Plan Recharge Duration": "Jätkutellimuse kestus",
      "cancel": "tühista",
      "Save": "Salvesta",
      "Filter Transaction": "Filtreeri tehing",
      "Service": "Teenindus",
      "Amount": "Summa",
      "Payment Status": "Makse staatus ",
      "Transaction Date": "tehingu kuupäev ",
      "previous": "eelmine",
      "Next": "järgmine",
      "Total Records:": "Kirjeid kokku:",
      "AutoApproval updated successfully": "Automaatse kinnituse värskendamine õnnestus",
      "Upload category Image": "Laadi tegevusala pilt",
      "Categories tittle": "Tegevusala",
      "Added Successfully": "Edukalt lisatud",
      "Category Approved Successfully": "Tegevusala edukalt kinnitatud",
      "Category Deleted Successfully": "tegevusala kustutamine õnnestus",
      "Updated Deleted Successfully": "Värskendatud kustutatud edukalt",
      "Vacancy Disapproved": "Vaba töökoht tagasi lükatud",
      "Vacancy Verified Successfully": "Vaba töökoht on edukalt kinnitatud",
      "Top-Up Successfully": "Täiendamine õnnestus",
      "On-boarded Client activated": "Sisseloginud klient aktiveeritud",
      "On-boarded Client deactivated": "Sisseloginud klient deaktiveeritud",
      "Activated Successfully": "Aktiveeritud edukalt",
      "Deactivated Successfully": "Deaktiveerimine õnnestus",
      "Response Send": "Vastuse saatmine",
      "Credit Top up": "Krediitide lisamine",
      "On-boarded Clients Enable": "Sisselogitud klientide ligipääsu lubamine",
      "On-boarded Clients Disab": "Sisselogitud klientide ligipääsu keelamine",
      "Credit for Premium": "Premiumi krediit",
      "Amount for Premium": "Premiumi summa",
      "Credit for Free": "Tasuta krediit",
      "Amount for Free": "Summa tasuta",
      "Credit for vacancy": "Krediit vaba töökoha eest",
      "Unlock duration(in Days)": "Avatud oleku kestus (päevades)",
      "Unlock duration": " Avatud oleku kestus",
      "Token Limits updated successfully": "Token Limite värskendati edukalt",
      "recruiter": "Värbaja",
      "Recruiter": "Värbaja",
      "Search by Company name": "Otsi ettevõtte nime järgi",
      "Add Company": "Lisa ettevõte",
      "Company Image": "Ettevõtte pilt",
      "Please upload file having extensions .jpeg/.jpg/.png only.": "Laadi üles fail, mille laiendid on ainult .jpeg/.jpg/.png."
    }
  }
}