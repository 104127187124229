// import { format } from 'core-js/core/date';
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
// import { format } from 'date-fns'
// import {useHistory}
import { useNavigate } from 'react-router-dom';
import { lang } from 'src/components/translation/lang';
import { useTranslation } from 'react-i18next'
const Details = () => {
    // const location = useLocation();  
    // const data =  location.state.data
    // console.log(data);
    const [ln,setLn] = useState('en')
    const [jobDetails, setJobDeails] = useState([]);
    const fromDate = jobDetails?.from_date
    console.log('fromDate', fromDate);
    var date1 = fromDate;
    date1 = date1?.split("-").reverse().join("-");
    console.log(date1)
    const toDate = jobDetails?.to_date
    console.log('fromDate', toDate);
    var date2 = toDate;
    date2 = date2?.split("-").reverse().join("-");
    console.log(date2)
    const { t } = useTranslation();

    // const history = useHistory();
    const navigate = useNavigate();
    function JobDetail(data) {
        console.log(data, "prsen tadata");
        fetch(
            "https://api.catzapp.com/jobseeker/vacancy_details/",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer  ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({ vacancy_id: localStorage.getItem("jobDetailId") }),
            }
        )
            .then((response) => response.json())
            .then((response) => setJobDeails(response.data));
        // .then((data) => console.log(data));
    }
    // const navigate =()=>{ history.push("/profile")}
    useEffect(() => {
        JobDetail();
    }, []);

    useEffect(()=>{
        if(localStorage.getItem("language") != null){
          setLn(localStorage.getItem("language"))
        }
      },[])
    console.log(jobDetails);
    console.log(jobDetails.company_details);
    console.log(jobDetails.skill_set);
    // useEffect(()=>{
    //     console.log(jobDetails.company_details);
    //     console.log(jobDetails.skill_set);
    // },[jobDetails])
    return (
        <div>
            <div className="container popup_job_role">
                <div className="job_detail_text fs-4"><b>{t("Job Details")}</b></div>
                <div className="row " style={{}}>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex job-detail-outer-left'>
                                <div className='left-detail'>        {t("Title")}</div>
                                <div className='right-detail'>     {jobDetails?.title}</div>
                            </div>
                            <div className='col-md-6  d-flex'>
                                <div className='left-detail'>        {t("Company Name")}:</div>
                                <div className='right-detail'>  {jobDetails?.company_details?.company_name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>       {lang && Object.keys(lang).length > 0 ? lang[ln]?.new["About Company"]:""}:</div>
                                <div className='right-detail'>     {jobDetails?.company_details?.about_company}</div>
                            </div>
                            <div className='col-md-6  d-flex'>
                                <div className='left-detail'>        {t("Company Location")}:</div>
                                <div className='right-detail'> {jobDetails?.company_details?.company_location}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>        {t("Job Category")}:</div>
                                <div className='right-detail'>           {jobDetails?.job_category_name}</div>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <div className='left-detail'>
                                    {t("Job Description")}:</div>
                                <div className='right-detail'> {jobDetails?.job_description}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>            {t("Number Of Vacancy")}:</div>
                                <div className='right-detail'>           {jobDetails?.no_of_vacancy}</div>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <div className='left-detail'>        {t("Maximum Application Allowed")}:</div>
                                <div className='right-detail'>   {jobDetails?.maximum_application_allowled}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>              {t("From Date")}:</div>
                                <div className='right-detail'>                  {date1}</div>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <div className='left-detail'>        {t("To Date")}:</div>
                                <div className='right-detail'>     {date2}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>                      {t("Skills")}:</div>
                                <div className='right-detail'>                {jobDetails?.skill_set?.map((item, i) => {
                                    console.log(jobDetails?.skill_set?.length)
                                    if (i == jobDetails?.skill_set?.length - 1) {
                                        return (
                                            item.skill_name)
                                    }
                                    return (
                                        item.skill_name + ', ')
                                })}</div>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <div className='left-detail'>        {t("Status")}:</div>
                                <div className='right-detail'>       {jobDetails?.status}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>        {t("Highest Degree Name")}:</div>
                                <div className='right-detail'>                 {jobDetails?.highest_degree_name}</div>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <div className='left-detail'>       {t("Degree Completion Year")}:</div>
                                <div className='right-detail'>           {jobDetails?.degree_completion_year}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>         {t("Employee Details")}:</div>
                                <div className='right-detail'>              {jobDetails?.emp_details}</div>
                            </div>
                            <div className='col-md-6  d-flex'>
                                <div className='left-detail'>          {t("Experience")}:</div>
                                <div className='right-detail'>          {jobDetails?.experience_name} Year</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>            {t("Licence Required")}:</div>
                                <div className='right-detail'>             {jobDetails?.liscense_required === true ? "Yes" : "No"}</div>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <div className='left-detail'>                   {t("Compensation Amount")}:</div>
                                <div className='right-detail'>      {jobDetails?.currency} {jobDetails?.compensation_amount} {jobDetails?.compensation_details}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>        {t("Contact Person Name")}:</div>
                                <div className='right-detail'>           {jobDetails?.contact_person_name}</div>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <div className='left-detail'>       {t("Contact Number")}:</div>
                                <div className='right-detail'>     {jobDetails?.contact_number}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="row job-detail-outer">
                            <div className='col-md-6 d-flex  job-detail-outer-left'>
                                <div className='left-detail'>     {t("Compensation Details")}:</div>
                                <div className='right-detail'>    {jobDetails?.compensation_details}</div>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <div className='left-detail'>     {t("Job Duration")}:</div>
                                <div className='right-detail'>   {jobDetails?.job_duration} Days</div>
                            </div>
                        </div>

                    </div>
                    {/* 
         <div className="col-12">
            <div className="row">
               <div className="col-2">
                  Compensation Details..... :
               </div>
               <div className="col-4">
                  {jobDetails.skill_set.skill_name}
               </div>
               <div className="col-2">
                  Search Radious:
               </div>
               <div className="col-4">
                  {jobDetails.search_radious}
               </div>
            </div>
         </div>
         */}
                </div>
                <div className="btn_of_cancl_apprv">
                    <button className="btn btn-sm btn-detail-back px-3 rounded-4" onClick={() => navigate(-1)} >{t("Back")}</button>
                    {/* <button className="btn btn-sm btn-outline-success px-3 rounded-4">Approve</button> */}
                </div>
            </div>
        </div>
    )
}
export default Details
